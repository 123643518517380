.fido_component_container {
  padding: 30px;

  .no_info_container {
    white-space: initial;
    max-width: none;
    max-height: none;
    width: auto;
    margin-bottom: 20px;
    overflow-x: unset;
    overscroll-behavior-y: unset;

    opacity: 1;
    transition: all $preview_open_duration ease-in-out;
    background: $bg-standard;
    box-shadow: $active-shadow;
    padding: 30px 30px;

    &__title {
      font-weight: $fw-700;
      display: flex;
      justify-content: center;
    }

    &__image_container {
      display: flex;
      justify-content: center;
    }

    &__point1 {

    }

    &__point2 {
      margin-top: 10px;
    }

  }

  .media_preview {
    position: relative;
    z-index: 100;
    white-space: initial;
    max-width: none;
    max-height: none;
    min-height: 600px;
    width: auto;
    margin-bottom: 20px;
    overflow-x: unset;
    overscroll-behavior-y: unset;

    @media screen and (max-width: 1000px) {
      max-width: none;
      max-height: none;
      //width: 100vw;
      margin-left: -30px;
      margin-right: -30px;
      border: none;
    }

    &__scroll_padding {
      position: absolute;
      top: -70px;
      height: 70px;
      z-index: 0;
      opacity: 0;

      @media screen and (max-width: 1000px) {
        top: -90px;
        height: 90px;
      }
    }
  }

  .top_navigation {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 15px;
  }
  .mini_list_navigation_line {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    position: relative;

    &__left {
      display: flex;
      align-items: center;
      cursor: pointer;
      &__title {
        margin-left: 10px;
        font-size: $fz-14;
        @media screen and (max-width: map_get($breakpoints, small)) {
          display: none;
        }
      }
      &__arrow {
        display: flex;
        align-items: center;
      }
      &.disabled {
        opacity: 0.3;
      }
    }

    &__title {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      font-weight: $fw-700;
    }

    &__right {
      display: flex;
      align-items: center;
      cursor: pointer;
      &__title {
        margin-right: 10px;
        font-size: $fz-14;
        @media screen and (max-width: map_get($breakpoints, small)) {
          display: none;
        }
      }
      &__arrow {
        display: flex;
        align-items: center;
      }
      &.disabled {
        opacity: 0.3;
      }
    }

  }

  .additional_information {
    &__header {
      font-weight: $fw-700;
      font-size: $fz-22;
      line-height: $lh-30;
    }

    &__item {

      display: flex;

      &__label {
        font-weight: $fw-700;
        font-size: $lh-14;
        line-height: $lh-19;
        margin-right: 20px;
        flex-basis: 140px;
      }
    }

    a {
      overflow-wrap: break-word;
    }

    .permanent_link_item {
      margin-top: 14px;
    }

  }

  .doc_info_container {
    margin-bottom: 35px;

    .document_type_header {
      font-weight: $fw-800;
      font-size: 24px;
      line-height: 33px;
      margin-bottom: 20px;
    }

    .contact_info_container {
      display: flex;
      margin-bottom: 20px;

      &__cover_container {
        img {
          width: 165px;
        }
      }

      &__contacts {
        margin-left: 20px;
        &__title {
          font-weight: $fw-800;
          font-size: 18px;
          line-height: 25px;
        }
        &__details {
          margin-top: 20px;
        }
      }
    }

    .buttons_line {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-content: center;
      width: 100%;
    }
  }

  @media screen and (max-width: map_get($breakpoints, medium)) {
    .document_and_metadata_container {
      flex-direction: column-reverse !important;
      .document-metadata {
        padding-left: 0 !important;
        padding-right: 0 !important;
        margin-right: 0 !important;
      }
    }
    .document .with-fixed-size {
      padding-right: 0;
    }
  }
}

//.modal__content {
//  padding: 30px;
//}
