@import '~js-datepicker/src/datepicker';
//overrides for our styling
.qs-datepicker-container {
  border-width: 0;
  box-shadow: $active-shadow;
  border-radius: 1.5em;
  padding: 1.5em;
  width: 18em;
  height: 18.5em;
  background-color: $bg-standard;
  color: $fg-standard;

  transform: translate3d(0, 0, 200px);

  .qs-controls {
    background: none;
    font-weight: $fw-600;
    font-size: $fz-standard;
    line-height: $lh-standard;
  }

  .qs-square {
    height: 2em;
    border-radius: 1em;
  }

  .qs-day {
    font-weight: $fw-standard;
    font-size: 14px;
    line-height: 19px;
    color: $shade-color;
  }

  .qs-active {
    background-color: $primary-color;
  }
  .qs-range-start {
    border-radius: 0;
    background:  radial-gradient(ellipse, $primary-color 70%, transparent 70%), linear-gradient(90deg, transparent 50%, $primary-light 50%)
  }

  .qs-range-end {
    border-radius: 0;
    background:  radial-gradient(ellipse, $primary-color 70%, transparent 70%), linear-gradient(90deg, $primary-light 50%, transparent 50%)
  }

  .qs-range-middle {
    background-color: $primary-light;
    color: $fg-standard;
  }

  .qs-month-year {
    cursor: default;
    border: none;
    &:hover {
      border: none;
    }
  }

  .qs-arrow.qs-right {
    &::after {
      border: none;
    }
  }
  .qs-arrow.qs-left {
    &::after {
      border: none;
    }
  }

  .qs-overlay {
    color: $fg-standard;
    background-color: $bg-standard;
    .qs-overlay-year {
      color: $fg-standard;
      background-color: $bg-standard;

    }
    .qs-close {
      top: .5em;
      right: 1em;
    }
    .qs-overlay-month {
      margin-top: 1em;
    }
  }
}
