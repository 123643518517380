.breadcrumb {
  margin-top: 40px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  padding: 0px;
  color: $shade-color;
  white-space: nowrap;

  a {
    text-decoration: none;
    color: $shade-color;

    &:hover {
      text-decoration: underline;
    }
  }

  &__element, &__divider {
    margin-right: 10px;
  }
}
