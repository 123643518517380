nav ul.pagination{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 5px;
  list-style: none;
  font-family: Open-Sans, sans-serif;
  font-style: $fs-standard;
  font-weight: $fw-standard;

  & li.page-item{
    width: auto;
    min-width: 32px;
    height: 32px;
    margin: 0 5px;
    text-align: center;
    padding-top: 4px;
    &.page-number{
      border: 1px solid $fg-standard;
      box-sizing: border-box;
      border-radius: 30px;
      font-size: 14px;
      &.selected {
        background: $fg-standard;
        color: $bg-standard;
      }
    }
    &.forward-back-nav{
      width: 24px;
    }
    & .page-link {
      display: block;
      text-decoration: none;
      color: $fg-standard;
      height: 100%;
    }
  }
}
