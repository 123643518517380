@import "../mixins/index";

.mein-genios-template {
  margin: 30px;
  max-width: 1200px;
  min-height: calc(100vh - 219px);

  @media screen and (max-width: map_get($breakpoints, small)) {
    margin: 15px;
  }

  .search-history {
    min-width: 70vw;

    @media screen and (max-width: map_get($breakpoints, medium)) {
      margin: 20px;
      min-width: initial;
    }
  }

  &__header {
    font-size: $fz-12;
    font-weight: $fw-standard;
    line-height: normal;
    margin-bottom: 15px;

    &--title {
      font-size: $fz-22;
      font-weight: $fw-700;
      margin-bottom: 10px;
    }


    &__info {
      font-size: 16px;
      font-weight: $fw-standard;
    }
  }

  &__body {
    margin-bottom: 7px;
  }

  &__content {
    height: 100%;

    .table-container {
      .custom-table {
        .checkbox {
          margin-bottom: 0;
          margin-left: 10px;

          .checkbox__icon {
            ion-icon {
              color: $primary-color;
              width: 20px;
              height: 20px;
            }
          }
        }

        th {
          padding-left: 5px;
        }

        td {
          padding: 10px 0 10px 5px;
        }
      }
    }

    .search-history__table {
      @media screen and (max-width: map_get($breakpoints, small)) {
        .custom-table {
          td {
            padding: 5px 0 5px 5px;
          }
        }

        .custom-table {
          colgroup:nth-child(1),
          colgroup:nth-child(5) {
            display: none;
          }
        }

        .custom-table tbody td:nth-child(1),
        .custom-table tbody td:nth-child(2) {
          display: block;
          width: 60vw; margin-bottom: -35px;
        }

        .custom-table tbody td:nth-child(1) {
          bottom: -50px;
          position: relative;
          left: -10px;
        }

        .custom-table tbody td:nth-child(3) {
          width: 20vw !important;
          max-width: 20vw;
        }

        .custom-table tbody td:nth-last-child(2),
        .custom-table tbody td:last-child {
          display: block;
          width: 20vw;
        }

        .custom-table thead th:nth-child(1),
        .custom-table thead th:last-child {
          display: none;
        }
      }

      @media screen and (max-width: map_get($breakpoints, xs)) {

        .custom-table tbody td:nth-child(1),
        .custom-table tbody td:nth-child(2) {
          display: block;
          width: 40vw !important;
        }

        .custom-table tbody td:nth-child(3) {
          width: 20vw !important;
          max-width: 20vw;

          span {
            -webkit-line-clamp: 1;
          }
        }

        .custom-table tbody td:nth-last-child(2),
        .custom-table tbody td:last-child {
          display: block;
          width: auto;
        }
      }
    }
  }

  &__variants {
    .form_element {
      margin-bottom: 0;
    }
  }

  .header-layout {
    position: sticky;
    flex-wrap: wrap;
    align-items: center;
    top: 70px;
    z-index: 10;
    background-color: $bg-standard;
    border-radius: $border-radius-20;
    margin: 0 -15px;
    padding: 8px 10px 8px 25px;
    display: flex;

    @media screen and (max-width: map_get($breakpoints, medium)) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      top: 115px;
      margin: 0;
      row-gap: 10px;
      padding: 3px 5px;
    }

    @media screen and (max-width: map_get($breakpoints, xs)) {
      grid-template-rows: auto auto;
      row-gap: 0;
    }

    @media screen and (max-width: map_get($breakpoints, xxs)) {
      top: 120px;
    }

    &.sticky-active {
      box-shadow: $active-shadow;
      transition: box-shadow 0.1ms ease;
    }

    &--checkbox {
      margin-bottom: 0;
      margin-right: 20px;
      width: fit-content;

      @media screen and (max-width: map_get($breakpoints, medium)) {
        grid-column: 1 / 3;
        order: 3;
        margin-right: 0;
        margin-left: 9px;
      }

      @media screen and (max-width: map_get($breakpoints, xs)) {
        grid-column: 1;
        grid-row: 2;
        order: 1;
      }

      .checkbox__icon {
        ion-icon {
          color: $primary-color;
          width: 20px;
          height: 20px;
        }
      }
    }

    &--action-bar {
      &--layout {
        position: relative;
        min-height: 40px;

        @include display();

        padding: 0 16px 0 14px;

        width: fit-content;

        @media screen and (max-width: map_get($breakpoints, medium)) {
          padding-left: 4px;
          padding-right: 4px;
        }

        @media screen and (max-width: map_get($breakpoints, xs)) {
          grid-column: 1 / 2;
          grid-row: 1;
        }

      }


      &--container {
        @include display();
      }
    }

    &--sorting {
      margin-left: auto;

      @media screen and (max-width: map_get($breakpoints, xs)) {
        grid-row: 2;
        order: 1;
        display: flex;
        justify-content: flex-end;
        margin-right: 10px;
      }

      @media screen and (max-width: map_get($breakpoints, xxs)) {
        grid-column: 1;
        margin-right: 0;
      }
    }

    &--selected {
      &-container {
        border-radius: 20px;
        box-shadow: $active-shadow;
        background-color: $bg-standard;
        position: absolute;
        top: 50px;
        z-index: 10;
        min-width: 250px;
        width: 100%;
        max-height: 360px;
        overflow: hidden;
        margin: 0 -16px 0 -14px;

        @media screen and (max-width: map_get($breakpoints, medium)) {
          margin: 0;
        }

        .line {
          margin: 0;
        }

        button[name="remove-all"] {
          margin-left: auto;

          span {
            font-size: $fz-standard;
            font-weight: $fw-standard;
            line-height: $lh-16;
          }
        }
      }

      &-list {
        max-height: 320px;
      }

      &-line {
        display: grid;
        grid-template-columns: 1fr 36px;
        align-items: center;
        justify-content: space-between;
        padding: 0 12px;
      }

      &-title {
        font-weight: $fw-standard;
        font-size: $fz-standard;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }

  .search-history__action-bar {
    @media screen and (max-width: map_get($breakpoints, medium)) {
      display: flex;
    }

    .form_element {
      @media screen and (max-width: map_get($breakpoints, medium)) {
        grid-column: 1;
        order: 1;
        grid-row: 1;
      }
    }

    .header-layout--action-bar--layout {
      @media screen and (max-width: map_get($breakpoints, medium)) {
        grid-column: 3;
        order: 1;
      }

      .select-toggler {
        @media screen and (max-width: map_get($breakpoints, medium)) {
          padding: 5px 10px;
        }
      }
    }
  }

  .content .list-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
}

.mein-genios-template.search-history {
  min-width: initial;
  width: 70vw;

  @media screen and (max-width: map_get($breakpoints, medium)) {
    width: initial;
  }
}

.basic-settings {
  margin-top: 35px;

  &__save-btn {
    margin: 60px 0;
  }

  &__group {
    &.flex-column {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      row-gap: 10px;
    }

    &--title {
      margin-bottom: 16px;
      font-size: 18px;
      font-weight: 700;
      line-height: 24px;
    }

    &--subtitle {
      margin-bottom: 12px;
      font-size: 16px;
      font-weight: 700;
      line-height: 22px;
    }

    &.flex-row {
      display: flex;
      flex-direction: row;
      column-gap: 60px;

      @media screen and (max-width: map_get($breakpoints, medium)) {
        column-gap: 0;
        justify-content: space-between;
        flex-direction: row;
      }

      @media screen and (min-width: 760px) and (max-width: map_get($breakpoints, medium)) {
        flex-direction: column;
        align-items: flex-start;
      }

      @media screen and (max-width: map_get($breakpoints, small)) {
        flex-direction: column;
        align-items: flex-start;
      }
    }
  }

  .header-layout--sorting {
    min-width: 200px;
  }
}

.flex-column-align-start {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 12px;
}

.header-layout--sorting {
  @media screen and (max-width: map_get($breakpoints, medium)) {
    border: none;
  }
}

.flex-column-max-height {
  display: flex;
  flex-direction: column;
  height: 100%;
}