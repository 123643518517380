.topmenu{
  $menu-selected-border: 2px solid $primary-color;

  display: block;
  white-space: nowrap;

  &__item{
    display: inline-flex;

    height: 46px;

    margin-right: 20px;

    &:last-of-type {
      margin-right: 40px;
    }

    &.selected-item{
      font-weight: $fw-700;
      border-bottom: $menu-selected-border;
      color: $primary-color;
    }

    a{
      text-decoration: none;
      color: inherit;
      display: inline-block;
      margin-top: 1em;
      &.deactivated {
        color: $shade-color;
      }

    }

    &:hover {
      color: $primary-color;
      border-bottom: $menu-selected-border;
    }

    &:active {
      color: $primary-color;
      border-bottom: $menu-selected-border;
    }

  }
}

li.topmenu__expandable{
  position: relative;

  background-image: url("/icons/dropdown.png");
  background-position: right;
  background-repeat: no-repeat;

  padding-right: 20px;
}

.mainmenu-submenu{
  display: none;
  margin-top: 0;
}

.submenu{
  position: absolute;
  z-index: 10;
  margin-left: -7px;
}
.submenu li{
  list-style-type: none;
  padding: 10px 5px;
  margin: 0;
  text-align: left;
}

.submenu li:not(:first-child){
  border-top: $light-border;
}

.topmenu li:hover .mainmenu-submenu{
  display: block;
}

#hit_list_switcher {
  position: absolute;
  right: 0;
}








