// -----------------------------------------------------------------------------
// This file contains all styles related to the user login of the site/application.
// --------------------------------------------------------------------------------

.login {
  display: flex;
  padding: 0;
  z-index: inherit;
  justify-content: flex-end;
  align-items: center;
  width: inherit;

  &__person__text {
    max-width: 300px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: $fz-14;
    font-weight: $fw-standard;

    @media screen and (max-width: map_get($breakpoints, small)) {
      display: none;
    }
  }

  &__options {
    position: absolute;
    right: 0;
    top: 40px;
    border-radius: $border-radius-20;
    width: fit-content;
    box-shadow: $active-shadow;
    background-color: $bg-standard;
    overflow: hidden;
    z-index: 120;

    button {
      justify-content: flex-end;
      border-radius: 0;
      width: 100%;
      background-color: $bg-standard;
      padding-right: 25px;
      color: $fg-standard;

      ion-icon {
        font-size: 20px;
        color: $fg-standard !important;
      }

      span:first-child {
        margin-left: 5px;
        font-size: 14px;

        &:first-letter {
          text-transform: uppercase;
        }

      }

      &:hover {
        transition: none;
        color: white;

        ion-icon {
          color: white !important;
        }
      }
    }
  }

  .submenu {
    right: 0;
    top: 60px;
  }

  li {
    display: flex;
    justify-content: space-between;
  }

  @media screen and (max-width: map_get($breakpoints, medium)) {
    justify-self: end;
    .login__bookmark {
      display: none;
    }
    .login__alarm {
      display: none;
    }

  }

  &__bookmark {
    margin-left: 10px;
  }

  &__alarm {
    margin-left: 10px;
  }

  .myGenios {
    min-width: 91px;
    white-space: nowrap;

    p {
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  @media screen and (max-width: map_get($breakpoints, medium)) {
    right: 16px;

    &__btn {
      p {
        display: none;
      }
    }
  }

  @media screen and (max-width: map_get($breakpoints, small)) {
    top: 16px;
  }
}
