//check

$vs-colors: (
        lightest: rgba(60, 60, 60, 0.26),
        light: rgba(60, 60, 60, 0.5),
        dark: #333,
        darkest: rgba(0, 0, 0, .15),
) !default;

//  Global Component Variables
$vs-component-line-height: 22px !default;
$vs-component-placeholder-color: inherit !default;

//  Active State
$vs-state-active-bg: $secondary-color !default;
$vs-state-active-color: $bg-standard !default;

//  Disabled State
$vs-state-disabled-bg: $bg-standard !default;
$vs-state-disabled-color: map_get($vs-colors, 'light') !default;
$vs-state-disabled-controls-color: map_get($vs-colors, 'light') !default;
$vs-state-disabled-cursor: not-allowed !default;

//  Borders
$vs-border-width: 1px !default;
$vs-border-style: solid !default;
$vs-border-radius: 4px !default;
$vs-border-color: map_get($vs-colors, 'lightest') !default;

//  Component Controls: Clear, Open Indicator
$vs-controls-color: map_get($vs-colors, 'light') !default;
$vs-controls-size: 1 !default;
$vs-controls-deselect-text-shadow: 0 1px 0 $bg-standard !default;

//  Selected
$vs-selected-bg: $bg-standard !default;
$vs-selected-border-color: $vs-border-color !default;
$vs-selected-border-style: $vs-border-style !default;
$vs-selected-border-width: $vs-border-width !default;

//  Dropdown
$vs-dropdown-z-index: 1000 !default;
$vs-dropdown-min-width: 160px !default;
$vs-dropdown-max-height: 350px !default;
$vs-dropdown-box-shadow: 0px 3px 6px 0px map_get($vs-colors, 'darkest') !default;
$vs-dropdown-bg: $bg-standard !default;


.form_element__input-dropdown {
  width: 100%;
  height: 100%;
  position: absolute;
  padding: 0 15px;

  $height: 27px;

  .vs__dropdown-option {
    min-height: $height;
    padding: 0 15px;
    line-height: $height;
    margin: 0;
  }

  .vs--open {
    position: absolute;
    border: 1px solid $fg-standard;
    height: 100%;
    width: 100%;
  }

  &.vs--single.vs--open .vs__selected {
    position: static;
  }

  .vs__dropdown-option:first-child {
    margin-top: -5px;
  }

  .vs__dropdown-option:last-child {
    margin-bottom: -5px;
  }

  .vs__dropdown-toggle {
    border: none;
    align-items: center;
    padding: 0;
    height: 100%;
    background-color: $bg-standard;
  }

  .vs__selected-options {
    width: 100%;
    overflow: hidden;
  }

  .vs__search {
    width: 0;
    height: 0;
    position: absolute;
    top: -10px;
    left: -10px;
  }

  .vs__actions {
    padding: 0;
  }

  .vs--single,
  .vs__selected {
    white-space: nowrap;
    font-size: $fz-standard;
    font-weight: $fw-standard;
    padding: 0;
    width: 100%;
    height: 100%;
    margin: 0;
  }

  .vs__dropdown-menu {
    border-radius: $border-radius-20;
    font-size: $fz-standard;
    margin-top: 15px;
    box-shadow: $dropdown-shadow;
    width: -webkit-fill-available;
    border: none;
    z-index: 2;
    overflow-x: hidden;
  }

  .vs__dropdown-option--highlight {
    background-color: $primary-color;
    color: white !important;
  }
}

#search_field .vs__search::placeholder,
#search_field .vs__dropdown-toggle {
  /*min-width: 170px;*/
  border: none;
  height: inherit;
  padding: 0;
  background: $bg-standard;
  color: $fg-standard;
}

#search_field .vs__dropdown-menu {
  border: none;
  width: 280px;
  border-radius: 20px;
  left: -15px;
  top: calc(100% + 10px);
  max-height: 500px;
  overflow-x: hidden;

  li {
    color: $fg-standard;

    &.vs__dropdown-option--highlight {
      color: white;
    }
  }

}

#search_field .vs__selected-options {
  height: initial;
}

#search_field .vs__selected {
  max-width: 12rem;
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
  width: max-content;
  margin: auto 0;
  padding: 0;
  border: none;
  position: static;
  background-color: $bg-standard;
  color: $fg-standard;
}

#search_field .vs__actions {
  margin: 0;
  padding: 0;
  border: none;
}

#search_field .vs__dropdown-option--highlight {
  background: $primary-color;
  color: white;
}


#search_field .vs__search:focus {
  margin-right: 2px;
  padding: 0;

}

#search_field .vs__search {
  margin-right: 2px;
  padding: 0;
}

.vs__selected {
  color: $fg-standard;
  background-color: $bg-standard;
}

.v-select {
  background-color: $bg-standard;
  color: $fg-standard;
}

.vs__dropdown-menu {
  background-color: $bg-standard !important;
}
