
.media_preview {
  display: flex;
  flex-direction: row;
  position: relative;
  z-index: 10;
  flex-grow: 0;
  flex-shrink: 0;
  max-width: $preview_width;
  width: $preview_width;

  max-height: 100vh;

  overflow-x: hidden;
  overscroll-behavior-y: contain;

  opacity: 1;
  transition: all $preview_open_duration ease-in-out;
  background: $bg-standard;
  box-shadow: $active-shadow;

  //cursor: grab;

  padding: 30px 30px;

  height: 100%;

  .media_preview__main__id[id^='FIDO'] {


    .media_preview__main_content__button_bar {
      justify-content: flex-end;

      .media_preview__action_bar__left {
        display: none;
      }
    }

    .doc_info_container {
      display: none;
    }

  }

  .fido_component_container {
    .document_and_metadata_container {
      flex-direction: column-reverse;
      padding: 0;
      margin: 0;

      .media_preview__main_content__button_bar {
        flex-direction: column;
        align-items: flex-end;
      }

      .document {
        border: none;

        .with-fixed-size {
          padding: 0;
          margin: 0;
          width: 100%;
        }
      }

      .mini_list_navigation_line {
        &__left, &__right {
          display: none;
        }
      }

      .document-metadata {
        width: 100%;
        padding: 0;
      }
    }
  }


  &__main {
    display: flex;
    flex-direction: column;
    min-width: $preview_content_width;
    max-width: $preview_content_width;
    height: max-content;
    padding-bottom: 60px;

    @media screen and (max-width: map_get($breakpoints, medium)) {
      min-width: $preview_content_width_medium;
      max-width: $preview_content_width_medium;
    }

    @media screen and (max-width: map_get($breakpoints, small)) {
      min-width: $preview_content_width_small;
      max-width: $preview_content_width_small;
    }
  }

  &__extra_line {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    //margin: 20px 0;
    height: 50px;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;

    &__full_article_link {
      display: none;
      color: $fg-standard;

      &.downloaded {
        display: inline;
      }
    }
  }

  &__action_bar {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__left {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

    }

    &__right {
      display: flex;
      flex-direction: row;

      #emailSendForm {
        right: 0;
        margin: 0 15px;
      }

    }


    &__price {
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;

      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      white-space: nowrap;

      &__pages {
        color: $shade-dark;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
      }
    }

    &__attachments {
      display: flex;
      margin-left: 15px;
      max-height: 20px;
    }

    &__icons {

      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      &__icon {
        margin: 8px
      }

      .disabled {
        opacity: 0.5;
      }

    }
  }

  &__buy_bar {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;

    &__price {
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;


      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      white-space: nowrap;
    }

  }

  &__button_bar {
    min-width: inherit;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    //overflow: hidden;

    margin: 0 0 20px;

    &__navigation {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    &__close {
      display: flex;
      flex-direction: row;

    }

    .close, .left, .right {
      flex-grow: 0;
      z-index: 1;
      //width: 44px;
      //height: 44px;
      display: flex;
      justify-content: center;
      align-items: center;


    }


  }

  @mixin flexi-props {
    .teaser .document .table-responsive .moduleDocumentTable td {
      display: flex;
      flex-direction: column;
      padding-top: 0;
    }
  }

  &__full_doc {
    margin-top: 15px;
  }

  &__full_doc, &__fido {

    &__teaser {

      &[documentid^="FIDO"] {

        .teaser {
          margin-bottom: 0;

          &__under_title {
            margin-bottom: 0;

            .fido_component_container {
              padding: 0;
            }
          }
        }

        .document__container {
          padding: 0;
        }

        .document__navigation {
          display: none;
        }
      }

      $DBs_Bonitaetsauskuenfte: "BDGE", "BONI", "BBGW", "BBUG", "CRED", "CREF", "BDCH", "BDEE", "BDNA", "BDAF", "BDFR", "BDGB", "BDIT", "BDJA", "BDLA", "BDME", "BDNE", "BDNL", "BDSO", "ABON", "BONT", "TABO";

      @each $DB in $DBs_Bonitaetsauskuenfte {
        &[documentid^=#{$DB}] {
          @include flexi-props;
        }
      }

      &__text {

      }
    }

  }

  &__main_content {
    min-width: inherit;
    word-wrap: break-word;
    display: inline-flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-bottom: 20px;
    margin-top: 20px;
    width: 100%;

    &.with-border {
      padding-bottom: 30px;
      border-bottom: $standard-border;
      margin-bottom: 30px;
    }

    &__preview {
      display: flex;
      flex-direction: column;

      &__image {
        flex-grow: 0;
        flex-shrink: 0;
        width: calc(min(140px, 50vw));
        height: 196px;

        flex: none;
        order: 0;
        flex-grow: 0;


        background-repeat: no-repeat;
        background-position: center;
        background-size: 100px;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;

        img {
          position: absolute;
          top: 0;
          display: block;
          max-width: 163px;
          max-height: 196px;
          width: auto;
          height: auto;
          margin: auto;
          filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.5));

          img.logo-preview {
            margin: 0 0 0 0;
            max-width: 163px;
            max-height: 245px;
            align-self: flex-end;
          }

          img.logo-search-preview {
            margin: 0 0 0 0;
            width: 163px;
            height: 245px;
          }
        }
      }

      &__link {
        display: flex;
        flex-direction: row;
        align-self: center;

      }

    }

    &__fields {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex-grow: 1;
      flex-shrink: 10;
      align-content: flex-start;

      align-items: flex-start;
      padding: 0;
      margin-left: 20px;


      &__source_logo {
        width: auto;
        height: auto;
        max-width: 92px;
        max-height: 100px;
        display: inline-flex;
        align-self: flex-end;
      }

      &__top_text {
        color: $shade-dark;
        font-size: 12px;
        line-height: 16px;
        display: flex;
        align-items: flex-start;
        font-weight: normal;
        margin-bottom: 15px;

      }

      &__name {
        color: $fg-standard;
        font-size: $fz-22;
        font-weight: $fw-800;
        line-height: $lh-30;
        display: flex;
        align-items: flex-start;
        margin-bottom: 5px;
        width: 100%;

        ion-icon {
          margin: 5px 0 0 5px;
          //--ionicon-stroke-width: 48px;
          color: $secondary-color;
        }

        .description {
          font-size: $fz-14;
          font-weight: $fw-standard;
          line-height: $lh-19;

          background-color: $bg-standard;

          top: 2rem;
        }
      }

      &__subtitle {
        width: inherit;
        margin-bottom: 5px;
        word-wrap: break-word;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        color: $shade-darker;
      }

      &__from {
        color: $shade-dark;
        font-weight: $fw-600;
        font-size: $fz-12;
        line-height: $lh-16;
        margin-bottom: 5px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }

      &__field_D {
        color: $shade-dark;
        font-weight: $fw-700;
        font-size: $fz-12;
        line-height: $lh-16;
        margin-bottom: 5px;
      }

      &__field_D.popup {
        color: $secondary-color;
      }

      &__field_I {
        display: table;
        font-size: $fz-14;
        line-height: $lh-19;

        &__row {
          display: table-row;
          max-width: 15rem;
        }

        &__name {
          display: table-cell;
          width: 1%;
          white-space: nowrap;

          font-weight: $fw-600;
        }

        &__value {
          display: table-cell;
          white-space: nowrap;
        }

        @media screen and (max-width: map_get($breakpoints, small)) {
          display: block;
        }
      }

      &__isdn {
        color: $shade-dark;
        font-weight: $fw-600;
        font-size: $fz-12;
        line-height: $lh-16;
        margin-bottom: 5px;
      }


      &__text {

        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        width: 100%;
        height: 100%;


        &__top {
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          height: 100%;
        }


      }

      &__bottom {
        display: flex;

      }

      &__fido {

        &__buy {

          //margin: 50px auto auto;
          height: fit-content;
          width: fit-content;

          .fido__button {
          }
        }
      }
    }

    &__logo {
      flex-grow: 0;
    }

    &__button_row {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
      width: 100%;

      button, a {
        margin-bottom: 20px;
      }

    }

    &__button_bar {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      @media screen and (max-width: map_get($breakpoints, small)) {
        flex-direction: column;
        align-items: flex-end;
        row-gap: 10px;
      }

      &__number_pages {
        font-size: $fz-12;
        line-height: $lh-16;
        color: $shade-dark;

        margin-right: 15px;
      }

      &__attachment {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 2px 5px;

        color: white;
        background-color: $shade-color;

        font-size: $fz-12;
        line-height: $lh-16;
        font-weight: $fw-600;

        margin-right: 10px;
        max-height: 20px;
        min-width: fit-content;
      }

    }

    @media screen and (max-width: map_get($breakpoints, medium)) {
      flex-direction: row;
    }

    @media screen and (max-width: map_get($breakpoints, xs)) {
      flex-direction: column;

      &__fields {
        margin: 0;
      }
    }

    .hideContent {
      overflow: hidden;
      max-height: 88px;
    }

    .showContent {
      max-height: none;
    }

    .show-more, .show-less {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      white-space: nowrap;

      padding-top: 5px;

      font-size: $fz-14;
    }

  }

  &__alarm_content {
    width: $preview_width;
    word-wrap: break-word;
    font-size: 14px;
    font-weight: bold;
    display: inline-flex;
    flex-direction: row;
    justify-content: flex-start;

    &.with-border {
      padding-bottom: 30px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      margin-bottom: 30px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }
  }

  &__further_info {

    &__text {
      white-space: pre-line;
    }

    .search_preview {
      max-width: $preview_content_width;
    }

    &__metadata {
      margin-top: 10px;

      display: table;
      border-collapse: separate;
      border-spacing: 0 0.5rem;

      &__row {
        display: table-row;
        max-width: 15rem;

        &__name {
          display: table-cell;
          width: 1%;
          white-space: nowrap;

          font-weight: $fw-600;
        }

        &__value {
          display: table-cell;
        }
      }

      @media screen and (max-width: map_get($breakpoints, small)) {
        display: block;

        &__row {
          display: block;
          margin-bottom: .5rem;
          width: calc(100vw - 60px);

          &__name, &__value {
            display: block;
            width: inherit;
          }
        }
      }

    }
  }


  &__further_info_ebook {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;

    margin: 40px 0;

    &__text {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0;


      &__title {

        font-weight: bold;
        font-size: $fz-22;
        line-height: 30px;
        margin-bottom: 20px;

      }

      &__copyright {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 0;
        flex: none;
        order: 2;
        flex-grow: 0;
        margin: 20px 0;
      }

      &__line {
        width: inherit;

      }

      &__table {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0;
        overflow-wrap: anywhere;


        table, tr, td {
          border-horizontal-spacing: 0 !important;
          border-vertical-spacing: 0 !important;
          border: none;
          vertical-align: top;
          font-size: 14px;
          line-height: 19px;
        }

        &__line {
          //display: flex;
          //flex-direction: row;
          //align-items: flex-start;
          //padding: 0;
          //margin: 10px 0;
          //
          //font-size: 14px;
          //line-height: 19px;

          &__key {
            white-space: nowrap;
            font-weight: bold;
            align-self: stretch;
            flex-grow: 0;
            padding-right: 10px;
          }

          //&__value {
          //  align-self: stretch;
          //  flex-grow: 0;
          //  word-break: break-all;
          //  padding-left: 10px;
          //}

        }

      }


      &__line_link {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0;

        flex: none;
        order: 1;
        flex-grow: 0;
        margin: 20px 0;

        &__key {
          font-weight: bold;
          font-size: 14px;
          line-height: 19px;
          align-self: stretch;
          flex-grow: 0;
        }

        &__value {
          align-self: stretch;
          flex-grow: 0;
          word-break: break-all;

          a.hyperlink {
            color: inherit;

            &:hover {
              color: $primary-color;
            }
          }
        }

      }
    }

    .search_preview {
      max-width: $preview_content_width;
    }
  }


  @media screen and (max-width: map_get($breakpoints, medium)) {
    position: fixed;
    //bottom: 0;
    left: 0;
    max-height: 95vh;
    z-index: 100;

    box-shadow: $active-shadow;

    max-width: $preview_width_medium;
    width: $preview_width_medium;
  }

  @media screen and (max-width: map_get($breakpoints, small)) {

    /*height: 300px;*/
    z-index: 1000;
    bottom: 0;
    padding: 20px;
    border-radius: $border-radius-10;
    transition: 0.3s ease-out;
    top: 130px;

    .media_preview__main {
      padding-bottom: 0;
      height: 100%;

      #firstBorderLine {
        display: none;
      }

      #hideBorderLine {
        display: none;
      }

      .border-line-mobile {
        display: block !important;
      }
    }
    .media_preview__main__id {
      //height: 100%;
      display: flex;
      flex-direction: column;
    }

    .media_preview__main_content {
      height: 100%;
      flex-direction: row;
      column-gap: 10px;

      .media_preview__main_content__fields__text__top {
        margin-top: 10px;

        .media_preview__main_content__fields__top_text {
          margin-bottom: 0;
        }
      }


      .media_preview__main_content__fields__subtitle {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-all;
      }

      .media_preview__main_content__fields__subname {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-all;
      }

      .media_preview__main_content__fields__name {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-all;
      }

      .media_preview__main_content__fields__field_D {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-all;
      }

      .media_preview__main_content__fields__text__tag_tile {
        display: block !important;
        width: fit-content;
        background: $secondary-color;
        padding: 2px 5px;
        color: white;
        font-weight: $fw-600;
        font-size: $fz-12;
        line-height: $lh-16;
      }

      .media_preview__action_bar__right {
        display: none;
      }
    }

    .media_preview__button_bar {
      margin: 0;
      justify-content: flex-end;

      &__navigation {
        display: none;

      }

      &__close {
        position: absolute;
        top: 22px;
        right: 13px;

      }
    }

    .media_preview__extra_line {
      display: none;
    }

    .media_preview__main_content__preview {
      flex-direction: row;

      .media_preview__button_bar__close {
        display: flex !important;
      }
    }

    .media_preview__further_info {
      //display: none;
    }

    .media_preview__buy_bar {
      display: none;
    }

    .media_preview__further_info_ebook {
      display: none;
    }


    .media_preview__full_doc {
      //display: none;
    }


    .media_preview__mobile_line {
      //display: flex !important;
      justify-content: space-between;
      align-content: center;
      flex-wrap: nowrap;
      align-items: center;
      margin-top: 5px;

      .left_aligned {
        align-content: center;
        flex-wrap: nowrap;
        align-items: center;
        display: flex;
        grid-gap: 5px;
        cursor: pointer;
      }

      &__show_more {

      }
    }
    //full preview
    &.full_preview {
      top: 0;
      height: 100vh !important;
      z-index: 1000;
      max-height: 110vh !important;

      #hideBorderLine {
        display: block;
      }

      .media_preview__main_content {
        flex-direction: column;
        column-gap: 0;
        height: auto;
      }

      .media_preview__main_content__fields__text__tag_tile {
        display: none !important;
      }

      .media_preview__main_content__fields__name {
        display: flex;
      }

      .media_preview__main_content__fields__field_D {
        display: flex;
      }

      .media_preview__main_content__fields__top_text {
        margin-bottom: 15px !important;
      }

      .media_preview__full_doc {
        display: block;
      }

      .media_preview__mobile_line {
        display: none !important;
      }

      .media_preview__extra_line {
        display: flex;
      }

      .media_preview__further_info {
        display: block;
      }

      .media_preview__buy_bar {
        display: flex;
      }

      .media_preview__further_info_ebook {
        display: flex;
      }

    }
  }

  @media screen and (min-width: 600px) and (max-width: 1000px) {
    z-index: calc(#{$divider_z_index} + 5);
  }

  &.width_zero {
    max-width: 0;
    width: 0 !important;
    padding: 30px 0 0;
    opacity: 0;

    @media screen and (max-width: map_get($breakpoints, medium)) {
      display: none;
    }
  }

  .grid-preview {
    display: inline-grid;
    grid-template-columns: auto auto auto auto;
    width: 100%;
    height: 100%;
  }

  .span-3columns {
    grid-column: 1 / span 3;
  }

  .fourth-column {
    grid-column: 4;
    text-align: end;
  }

  .hideContent {
    overflow: hidden;
    max-height: 88px;
  }

  .showContent {
    max-height: none;
  }

  .show-more, .show-less {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    white-space: nowrap;

    padding-top: 5px;

    font-size: $fz-14;
  }


}
