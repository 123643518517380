.gallery {
  display: flex;
}

.gallery-component {
  flex: 1;
  padding: 20px;

  h2 {
    text-transform: uppercase;
    border-bottom: 1px solid $fg-standard;
    padding-bottom: 20px;
  }

  &__wrapper {
    padding-top: 20px;
  }

  &__content {
    border: 1px dashed $shade-color;
    padding: 20px;

    > div > .range {
      display: grid;
    }
  }
}