.flex-swimlane {
  list-style: none;
  display: flex;
  width: 100%;
  overflow-x: auto;

  .swimlane-item {
    width: auto;
    height: auto;
  }

  .swimlane-item:first-child{
    margin-left: -$item-margin;
  }
  .swimlane-item:last-child{
    margin-right: -$item-margin;
  }
}
