.action_bar_container {
  background-color: $bg-standard;
  position: relative;
  z-index: 2;

  @media screen and (max-width: map_get($breakpoints, medium)) {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  #emailSendForm {
    z-index: 400;
    left: 0;
    top: 45px;
  }

  .action_buttons_line {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 3px;

    .header-layout--checkbox {
      margin-bottom: 0;
      padding: 5px 40px 5px 5px;
    }

    ion-icon {
      visibility: visible;
    }

    .button__secondary.smaller-padding {
      padding: 5px 8px;
    }

    .search_button {
      margin-right: 75px;
      position: static;
    }

    .checked_document_count {
      display: flex;
      margin-left: 6px;
      flex: none;
    }

    @media screen and (max-width: map_get($breakpoints, small)) {
      .checked_document_count_text {
        display: none;
      }
    }

    .action_item {
      padding: 0 6px 0 6px;
    }

    .flash_item {
      animation: fadein 1s;
      border-radius: 20px;
    }

    @keyframes fadein {
      from {
        background-color: $primary-color;
      }
      to {
        background-color: $bg-standard;
      }
    }

    .highlight_item {
      padding: 2px 2px;
      border-radius: 20px;
      box-shadow: $active-shadow;
      margin: -2px 6px 3px 3px;
    }
  }

  .header-layout--checkbox {
    margin-bottom: 0;
    padding: 0 0 5px 8px;
  }

  .checked_documents_container {
    border-radius: 20px;
    box-shadow: $active-shadow;
    background-color: $bg-standard;
    position: absolute;
    z-index: 10;
    min-width: 250px;
    margin-top: 13px;
    width: 100%;
    max-height: 360px;
    overflow: hidden;

    .button__secondary, .button__primary:visited {
      padding: 0;
      background: none !important;
      margin-bottom: 5px;
      margin-left: 12px;

      span {
        font-weight: 400;
      }

      .lds-ring {
        div {
          border-color: $fg-standard transparent transparent transparent
        }
      }
    }

    .checked_documents_list {
      max-height: 320px;

      .checked_document_line {
        display: grid;
        grid-template-columns: 1fr 36px;
        align-items: center;
        justify-content: space-between;
        padding: 0 12px;

        .checked_document_title {
          font-weight: $fw-600;
          font-size: $fz-14;
          line-height: $lh-19;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

      }

    }

    .danger_remove_all_line {
      padding: 6px 0 6px 0;
      color: $danger-color;
      font-size: $fz-14;
    }

    button.danger_remove_all_line:hover {
      text-decoration: underline;
    }

  }

  .checkbox__icon {
    ion-icon {
      width: 20px;
      height: 20px;
    }
  }
}

.action_bar_container.sticky_action_bar {
  position: fixed;
  z-index: 3;
  top: 120px;
  border-radius: 20px;
  box-shadow: $active-shadow;

  @media screen and (max-width: map_get($breakpoints, medium)) {
    top: 166px;
  }

}

.browse_page {

  .action_bar_container.sticky_action_bar {
    top: 63px;
    right: 11px;
    margin-top: 18px;

    @media screen and (max-width: map_get($breakpoints, medium)) {
      top: 113px;
      right: 8px;
    }
  }

  .action_bar_container {
    margin-right: 25px;
  }

}


.preview_opened {
  .browse_page {
    .action_bar_container.sticky_action_bar {
      right: 590px;
    }
  }
}
