button {
  padding: 0;
  border: none;
  font: inherit;
  color: inherit;
  background-color: $bg-transparent;
  cursor: pointer;

  &:hover, &.active {
    outline: 0;
  }
}

button.transparent-button {
  background: none;
}

.buy_button {
  &__preview {
    &__ebook {
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
}
