//for 2 lines ellipsis ['...']
//height: some pixels
//overflow: hidden;
//text-overflow: ellipsis;
//display: -webkit-box;
//-webkit-line-clamp: 3;
//-webkit-box-orient: vertical;

@import "../mixins/index";

.article {
  display: grid;
  grid-template-columns: 140px 1fr 90px;
  background: $shade-light;
  border-radius: $border-radius-10;
  padding: 20px;
  margin: 10px 0;
  width: 100%;
  max-width: 1200px;

  &.list-view {
    position: relative;
    grid-template-columns: 10px 70px auto;
    padding: 15px 25px 15px 15px;
    background: $bg-standard;
    border: 1px solid $shade-color;
    gap: 25px;

    @media screen and (max-width: map-get($breakpoints, medium)) {
      gap: 10px;
      display: flex;
      flex-direction: column;
      padding: 15px 20px 15px 15px;
    }

    &:hover,
    &:active,
    &:focus,
    &:focus-within {
      background-color: inherit;
      border: 1px solid $primary-dark;
      border-radius: 10px;

      .article__text__bar__number_pages {
        @media screen and (max-width: map-get($breakpoints, xs)) {
          display: flex;
        }
      }
    }

    .article__img {
      height: auto;

      @media screen and (max-width: map-get($breakpoints, medium)) {
        display: block;
      }

      &__source {
        display: block;

        img {
          width: 16px;
        }
      }
    }

    .article__checkbox {
      visibility: visible;
      opacity: 1;
      margin: 15px;

      @media screen and (max-width: map-get($breakpoints, medium)) {
        margin: 12px;
      }

      .checkbox__icon {
        ion-icon {
          color: $primary-color;
        }
      }
    }

    .article__text {
      padding: 0;

      &__container{
        display: flex;
        gap: 5px;
        align-items: center;

        @media screen and (max-width: map-get($breakpoints, medium)) {
          order: 2;
          grid-row: 2;
        }
      }

      &__source {
        margin-bottom: 0;
        line-height: 21px;
        font-weight: 700;

        &.search-purpose {
          display: flex;
          justify-content: flex-end;
          color: $fg-standard;
          font-weight: normal;
        }
      }

      &__supertitle {
        font-size: $fz-standard;
        grid-column: 1;
        grid-row: 2;

        @media screen and (max-width: map-get($breakpoints, medium)) {
          order: 2;
          grid-row: 3;
        }
      }

      &__panelHeader {
        display: grid;
        grid-template-columns: minmax(0, 1fr) auto;
        gap: 5px;
        font-size: 12px;

        @media screen and (max-width: map-get($breakpoints, xs)) {
          grid-template-columns: 1fr;
        }
      }

      &__bar {
        min-height: 34px;
        height: fit-content;

        @media screen and (max-width: map-get($breakpoints, medium)) {
          flex-direction: column;
          gap: 15px;
        }

        &__number_pages {
          @media screen and (max-width: map-get($breakpoints, medium)) {
            flex-direction: row;
            justify-content: space-between;
            width: 100%;
            margin-right: 0;
            flex-wrap: wrap;
          }

          .article-type__container {
            display: flex;
            gap: 5px;

            .refresh-solution {
              background: var(--secondary-color);
              padding: 2px 5px;
              color: white;
              font-weight: 600;
              font-size: 12px;
              line-height: 16px;
              white-space: nowrap;
            }
          }
        }

        &__actions {
          align-items: center;
          justify-content: flex-end;
          flex-direction: row;
        }
      }
    }

    .article__source {
      display: flex;
      flex-direction: column;
      align-items: center;
      align-content: center;
      gap: 5px;
      margin-top: 3px;
      margin-left: 10px;

      @media screen and (max-width: map-get($breakpoints, medium)) {
        flex-direction: row;
        margin-top: 0;
        margin-left: 0;
        justify-content: flex-end;
        gap: 10px;
      }

      &__date {
        display: flex;
        flex-direction: row;
        align-items: center;
        align-content: center;
        gap: 5px;

        &.bold {
          font-weight: 700;
          color: $fg-standard;
        }

        ion-icon {
          height: 12px;
          width: 12px;
        }
      }
    }

    .article__functions {
      position: unset;
      align-items: center;

      @media screen and (max-width: map-get($breakpoints, medium)) {
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
      }

      .watchlist_dialog {
        position: absolute;
        z-index: 120;
        top: 40px;
        left: auto;
        right: 0;

        @media screen and (max-width: map-get($breakpoints, medium)) {
          top: 40px;
          right: auto;
          width: 70vw;
        }
      }

      .only-on-hover {
        display: flex;

        @media screen and (max-width: map-get($breakpoints, medium)) {
          visibility: visible;
          opacity: 1;
        }

        .watchlist-toggle-container {
          @media screen and (max-width: map-get($breakpoints, medium)) {
            position: unset;
          }
        }

        #emailSendForm {
          @media screen and (max-width: map-get($breakpoints, medium)) {
            position: absolute;
            z-index: 120;
            top: 40px;
            left: 0;
            max-width: 80vw;
          }
        }
      }

      .bookmarked {
        @media screen and (max-width: map-get($breakpoints, medium)) {
          visibility: visible;
          opacity: 1;
        }
      }

      .tooltip-button {
        margin-right: 0;


        @media screen and (max-width: map-get($breakpoints, medium)) {
          width: fit-content;
          min-width: fit-content;
        }

        .buy_button {
          margin-left: 10px;

          @media screen and (max-width: map-get($breakpoints, medium)) {
            visibility: visible;
            opacity: 1;
          }
        }
      }
    }
  }



  &.refresh-solution {
    grid-template-columns: 46px 1fr 25px;
    padding: 12px 0 25px 0;
    background: $bg-standard;
    border: 1px solid $shade-color;
    position: relative;

    @media screen and (max-width: map-get($breakpoints, medium)) {
      grid-template-columns: 1fr 90px;
      padding: 20px;
    }

    .article__checkbox {
      @media screen and (max-width: map-get($breakpoints, medium)) {
        visibility: visible;
        opacity: 1;
      }
    }

    .article__date {
      &--icon {
        display: flex;
        flex-direction: row;
        gap: 5px;
        align-items: center;
        line-height: 16px;

        ion-icon {
          width: 12px;
          height: 12px;
        }
      }
    }
  }

  @media screen and (max-width: map-get($breakpoints, medium)) {
    grid-template-columns: 1fr 90px;
  }

  &__img {
    position: relative;
    height: 196px;

    &.refresh-solution {
      height: initial;
    }

    @media screen and (max-width: map-get($breakpoints, medium)) {
      display: none;
    }

    img {
      filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.5));
      max-width: 100%;
    }

    .article__img__tag_row {
      display: block;

      @media screen and (max-width: map-get($breakpoints, medium)) {
        display: none;
      }
    }

    &__tag_tile, &__tag_row {
      background: $secondary-color;
      position: absolute;
      padding: 2px 5px;
      color: white;
      margin: 0 10px;
      font-weight: $fw-600;
      font-size: $fz-12;
      line-height: $lh-16;

      @media screen and (max-width: map_get($breakpoints, medium)) {
        margin: 0 2px;
      }
    }

    &__tag_tile {
      display: block;
      bottom: 20px;
      right: -30px;
      position: absolute;
      width: min-content;

      @media screen and (max-width: map_get($breakpoints, medium)) {
        right: 0;
        margin: 0;
      }
    }

    &__tag_row {
      right: -10px;
      top: calc(200px - 50px);
    }

    &__source {
      display: none;
      grid-template-columns: 30px 1fr;
      align-items: center;
      gap: 5px;
      font-size: 15px;

      img {
        width: 27px;
      }

      @media screen and (max-width: map_get($breakpoints, medium)) {
        display: grid;

        span {
          display: none;
        }
      }

      @media screen and (max-width: map_get($breakpoints, small)) {
        font-size: 12px;
      }

      @media screen and (max-width: map_get($breakpoints, xs)) {
        line-height: 15px;
      }
    }

    &__video {
      flex: none;
      order: 0;
      flex-grow: 0;
      min-width: 120px;
      height: 120px;

      &__tag {
        background: $secondary-color;
        position: absolute;
        right: 0;
        top: calc(135px - 50px);
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 2px 5px;

        p {
          font-style: $fs-standard;
          font-weight: $fw-600;
          font-size: $fz-12;
          line-height: $lh-16;
          color: $bg-standard;
          flex: none;
          order: 0;
          flex-grow: 0;
          margin: 0 10px;
        }
      }
    }
  }

  &__selection {
    flex: 0 0 100px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__text {
    word-break: break-word;
    overflow-wrap: break-word;
    hyphens: auto;
    padding: 0 20px;
    display: grid;
    grid-template: auto 1fr / 1fr;

    @media screen and (max-width: map-get($breakpoints, medium)) {
      display: block;
      grid-column: 1 / 3;
      grid-row: 1;
      padding: 0;
    }

    &.refresh-solution {
      padding: 0 0 0 10px;
    }

    .article__img {
      display: none;

      &.refresh-solution {
        @media screen and (max-width: map-get($breakpoints, medium)) {
          margin-bottom: 0;
        }
      }

      @media screen and (max-width: map-get($breakpoints, medium)) {
        display: block;
        width: 60px;
        float: left;
        height: fit-content;
        max-height: 84px;
        margin-right: 10px;
        margin-bottom: 5px;
      }
    }

    &__source {
      font-weight: normal;
      font-size: $fz-12;
      line-height: $lh-16;
      color: $shade-dark;
      margin-bottom: 5px;
    }

    &__supertitle {
      color: $primary-darker;
    }

    &__title {
      margin: 0;
      font-weight: 800;
      font-size: 18px;
      line-height: $lh-30;

      @media screen and (max-width: map-get($breakpoints, medium)) {
        margin-top: 10px;
        line-height: 25px;
        text-decoration: none !important;
        mark {
          text-decoration: none !important;
        }
      }
    }

    &__subtitle {
      margin: 5px 0;
      font-weight: $fw-600;
      font-size: $fz-standard;
      line-height: $lh-standard;
      color: $shade-darker;

      @media screen and (max-width: map-get($breakpoints, medium)) {
        text-decoration: none !important;
        mark {
          text-decoration: none !important;
        }
      }
    }

    &__start {
      font-weight: $fw-600;
      font-size: 12px;
      line-height: 16px;
      color: $shade-dark;
      flex: none;
      align-self: stretch;
      flex-grow: 0;
      margin: 10px 0;
    }

    &__trefferumgebung {
      margin-top: 5px;
      color: $shade-dark;
    }

    &__remarks {
      font-size: 12px;
      color: gray;
      margin-top: 20px;
      margin-bottom: 40px;
    }

    .button-panel {
      justify-content: flex-start;
    }

    &__bar {
      display: flex;
      align-items: center;
      height: 34px;
      margin-top: 16px;

      &.refresh-solution {
        @media screen and (max-width: map_get($breakpoints, medium)) {
          display: grid;
          height: auto;
        }

        .article__text__bar__attachment {
          width: fit-content;
        }

        .buy_button {
          @media screen and (max-width: map_get($breakpoints, small)) {
            margin-left: 0;
          }
        }

        .article__text__bar__actions:not(:has(#emailSendForm, .watchlist_dialog, .confirm_purchase)) {
          @media screen and (max-width: map_get($breakpoints, medium)) {
            grid-column: span 2;
            margin-top: 18px;
          }

          @media screen and (max-width: map_get($breakpoints, xs)) {
            grid-column: span 1;
          }
        }
      }

      .article__img__tag_row {
        display: none;

        @media screen and (max-width: map_get($breakpoints, medium)) {
          :not(.refresh-solution) {
            display: unset;
            top: unset;
            right: 19px;
          }
        }
      }

      &__actions {
        display: flex;
        width: 100%;
        justify-content: space-between;

        &.refresh-solution {
          justify-content: flex-start;

          .tooltip {
            margin-right: 0;
          }

          .article__functions {

            .watchlist_dialog {
              &.delete_dialog {
                top: 40px;
                @media screen and (max-width: map_get($breakpoints, medium)) {
                  left: 0;
                }
              }

              &.add_dialog {
                top: 40px;
                @media screen and (max-width: map_get($breakpoints, medium)) {
                  left: 0;
                }
              }
            }

            .watchlist_dialog:last-child {
              &.delete_dialog {
                top: 40px;
              }
            }

            #emailSendForm {
              top: 40px;

              @media screen and (max-width: map_get($breakpoints, medium)) {
                left: 0;
              }
            }
          }

          .buy_button {
            margin-left: 0;
          }

          @media screen and (max-width: map_get($breakpoints, small)) {
            flex-direction: row;
            justify-content: space-between;
          }
        }

        .buy_button {
          margin-left: 50px;

          @media screen and (min-width: 600px) and (max-width: 1000px) {
            margin-left: 0;
          }
        }

        .button__primary:not([disabled]) {
          background: $primary-color;

          &:hover {
            background: $primary-dark;
          }

          &:active {
            background: $primary-darker;
          }

          &:visited {
            color: $fg-standard;
            background: $shade-light;
            border-color: $fg-standard;

            &:hover {
              background-color: $bg-standard;
            }

            &:active {
              background: $shade-light;
            }

            .lds-ring {
              div {
                border-color: $fg-standard transparent transparent transparent
              }
            }
          }
        }
      }

      &__number_pages {
        font-size: $fz-12;
        line-height: $lh-16;
        color: $shade-dark;
        margin-right: 10px;
        min-width: fit-content;
        display: flex;
        gap: 5px;
        align-items: center;
        text-wrap: nowrap;

        @media screen and (max-width: map_get($breakpoints, small)) {
          grid-column: span 1;
        }

        >.refresh-solution {
          background: $secondary-color;
          padding: 2px 5px;
          color: white;
          font-weight: $fw-600;
          font-size: $fz-12;
          line-height: $lh-16;
          white-space: nowrap;
        }
      }
    }

    &__panelHeader {
      color: $fg-standard;
      cursor: pointer;
      pointer-events: none;

      .article__text__title {
        display: none;
      }

      &.refresh-solution {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 4px;
        justify-content: start;
        width: 70%;

        @media screen and (max-width: map_get($breakpoints, medium)) {
          margin-top: 20px;
        }

        @media screen and (max-width: map_get($breakpoints, small)) {
          width: 100%;
        }

        >.article__text__source {
          margin-bottom: 0;
        }
      }
    }

    &__panelBody {
      overflow-wrap: anywhere;
      word-wrap: anywhere;
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: space-between;

      @media screen and (max-width: map_get($breakpoints, medium)) {
        display: unset;
      }

      &__details {
        cursor: pointer;
        color: $fg-standard;
        @media screen and (max-width: map_get($breakpoints, medium)) {
          text-decoration: none !important;
          pointer-events: none;
        }
      }
    }
  }

  &__source {

    &.refresh-solution {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(60vw, 1fr));
      position: absolute;
      margin-top: 20px;
      right: 25px;
      gap: 18px;
      font-size: $fz-12;
      font-weight: $fw-600;

      .article__date--icon:not(:first-of-type) {
        color: $fg-standard;
      }

      @media screen and (max-width: map_get($breakpoints, medium)) {
        margin-top: -5px;
      }

      > div {
        grid-row: 1 / 2;
        justify-self: end;
      }
    }

    @media screen and (max-width: map_get($breakpoints, medium)) {
      grid-row: 1 / 2;
      grid-column: 2;
    }

    &__image {
      @media screen and (max-width: map_get($breakpoints, medium)) {
        display: none
      }

      img {
        max-width: 92px;
      }
    }

    &__date {
      font-size: $fz-12;
      line-height: $lh-16;
      color: $shade-dark;
      text-align: right;
    }
  }

  &__functions {
    display: flex;
    right: 16px;
    bottom: 20px;
    z-index: 1;

    @media screen and (min-width: map_get($breakpoints, medium)) {
      position: absolute;
    }

    .button__icon, .button__secondary, .button__primary:visited {
      background: none;

      .lds-ring {
        div {
          border-color: $fg-standard transparent transparent transparent
        }
      }
    }
  }

  &__functions .only-on-hover, .buy_button, .article__checkbox {
    will-change: transform;
    transition: visibility 0.3s, opacity 0.2s ease-in-out;
    visibility: hidden;
    opacity: 0;
  }

  &__functions .bookmarked {
    @media screen and (max-width: map_get($breakpoints, medium)) {
      will-change: transform;
      transition: visibility 0.3s, opacity 0.2s ease-in-out;
      visibility: hidden;
      opacity: 0;
    }
  }

  .article__checkbox {
    -webkit-appearance: none;
    position: absolute;
    background-size: cover;
    left: -12px;
    top: -10px;

    &.refresh-solution {
      left: 15px;
      top: 35px;
      visibility: visible;
      opacity: 1;
    }

    &.primary-color {
      .form_element__label .checkbox__icon ion-icon {
        color: $primary-color;
        width: 20px;
        height: 20px;
      }
    }

    &:checked {
      background-color: $bg-transparent;
      width: 24px;
      height: 24px;
      left: -16px;
      top: -15px;
    }
  }
}

.article-source {
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  flex-wrap: wrap;
  justify-content: end;

  >.article__date {
    margin-left: auto;
    display: flex;
    flex-direction: row;
    gap: 18px;
    font-size: $fz-12;
    font-weight: $fw-600;
    line-height: normal;
  }
}

.article {
  will-change: transform;
  transition: box-shadow 0.1s ease-in-out;

  &:hover {
    z-index: 1;
  }

  &:active,
  &:focus,
  &:focus-within {
    z-index: 2;
  }

  &.refresh-solution {
    &:hover,
    &:active,
    &:focus,
    &:focus-within {
      border-radius: 10px;
      border: 1px solid $primary-color;
      background-color: inherit;

      .article__text__bar__number_pages,
      .article__text__bar__attachment {
        @media screen and (max-width: map-get($breakpoints, xs)) {
          display: flex;
        }
      }
    }

    &.selected {
      background-color: inherit;
    }

    .article__functions .only-on-hover, .buy_button {
      display: flex;
      justify-content: center;
      align-items: center;

      @media screen and (max-width: map_get($breakpoints, small)) {
        visibility: visible;
        opacity: 1;
      }
    }
  }

  &:hover,
  &:active,
  &:focus,
  &:focus-within {
    box-shadow: $active-shadow;
    background: $shade-light;

    .article__text__bar__number_pages,
    .article__text__bar__attachment {
      @media screen and (max-width: map-get($breakpoints, medium)) {
        display: none;
      }
    }

    .article__text__title {
      text-decoration: underline;

      mark {
        text-decoration: underline;
      }
    }

    .article__text__subtitle {
      text-decoration: underline;

      mark {
        text-decoration: underline;
      }
    }

    .article__functions .only-on-hover, .buy_button, .article__checkbox {
      visibility: visible;
      opacity: 1;
    }

    .article__functions .bookmarked {
      @media screen and (max-width: map_get($breakpoints, medium)) {
        visibility: visible;
        opacity: 1;
      }
    }

    .article__text__bar {
      .article__img__tag_row {
        display: none;
      }
    }
  }


  &.selected {
    background-color: $secondary-light;

    .article__checkbox {
      visibility: visible;
      opacity: 1;
    }
  }
}

.media-article {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  background: $shade-light;
  border-radius: $border-radius-10;
  padding: 20px;

  .no_background {
    background: none;
  }

  button.select {
    margin-bottom: -5px;

    img {
      width: 30px;
      height: 30px;
    }
  }

  &__img {
    height: 229px;
    margin-bottom: 20px;
    margin-left: 20px;
    margin-right: 20px;
    position: relative;
  }

  &__big-img {
    height: 540px;
    width: 420px;
    margin: 10px auto;
  }

  &__text {
    display: flex;
    flex-direction: column;

    height: 102px;
    width: calc(100% - 40px);
    position: relative;
    margin: 20px;

    .article__text__source-icon {
      display: none;
    }

    & > * {
      flex-shrink: 0;
    }

    &__field_AE {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      overflow: hidden;
      text-overflow: ellipsis;

      font-size: $fz-12;
      line-height: $lh-16;

      margin-bottom: 5px;
      color: $shade-dark;

      &__A, &__E {
        display: block;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      &__A {
        margin-right: 5px;
      }

      &__E {
        flex-shrink: 0;
      }

      @media screen and (max-width: map_get($breakpoints, medium)) {
        font-size: $fz-10;
        line-height: $lh-14;
        min-height: 14px;
      }
    }

    &__field_B {
      font-weight: $fw-800;
      font-size: $fz-standard;
      line-height: $lh-standard;

      height: calc(2 * #{$lh-standard});
      margin-bottom: 5px;

      display: -webkit-box;
      //noinspection CssUnknownProperty
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;

      span {
        @extend .media-article__text__field_B;
        overflow: hidden;
      }


      @media screen and (max-width: map_get($breakpoints, medium)) {
        font-size: $fz-14;
        line-height: $lh-19;

        height: calc(2 * #{$lh-19});
      }
      @media screen and (max-width: map_get($breakpoints, small)) {
        height: fit-content;
        -webkit-line-clamp: unset;

        &[data-title] {
          all: unset;

          &:before {
            all: unset;

          }

          &:after {
            all: unset;

          }

          &:hover {
            all: unset;
          }
        }

        span {
          //overflow: initial;
          height: 40px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }
    }

    &__field_D {
      display: block;
      font-size: $fz-12;
      line-height: $lh-16;
      color: $shade-dark;

      font-weight: normal;

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      @media screen and (max-width: map_get($breakpoints, medium)) {
        font-size: $fz-10;
        line-height: $lh-14;
      }
    }

    &__actions {
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: space-between;
      align-items: center;

      button[aria-label="bookmark"] {
        background: none;
      }

      button[class^='button__']:not([class$="icon"]), .bookmark {
        display: none;
      }
    }

    @media screen and (max-width: map_get($breakpoints, medium)) {
      margin: 10px;
    }
  }


  @media screen and (max-width: map_get($breakpoints, large)) {
    &__img {
      margin-left: 0;
      margin-right: 0;
    }
  }

  @media screen and (max-width: map_get($breakpoints, medium)) {
    &__img {
      margin-left: 0;
      margin-right: 0;
    }
  }

}

.media-article:hover {
  cursor: pointer;
}

.alternative_article {
  display: grid;
  grid-template-columns: 140px 1fr 90px;
  column-gap: 10px;
  background: $shade-light;
  border-radius: $border-radius-10;
  padding: 20px;
  width: 100%;

  &#alternative_grid {
    display: none;
  }

  &__container {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
  }

  &__title {
    font-size: 16px;
    line-height: 22px;
    font-weight: 600;
    white-space: nowrap;
    margin: 0;
  }

  &__item {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;

    img {
      width: 100%;
      height: fit-content;
    }
  }

  &__paragraph {
    margin: 0;
    color: $shade-dark;

    &.date {
      font-size: $fz-12;
      text-align: right;
    }
  }

  &__absolute {
    position: absolute;
    right: 0;
    bottom: 50px;
    background: $secondary-color;
    color: white;
    padding: 0 7px;
    font-size: $fz-12;
  }

  &__actions {
    margin-top: auto;
  }

  &__link {
    color: $fg-standard;

    &:hover {
      text-decoration: underline;
    }
  }

  @media screen and (max-width: map-get($breakpoints, 'small')) {
    display: none;

    &#alternative_grid {
      display: grid;
      grid-template-columns: 1fr;
      max-width: 220px;
      row-gap: 10px;
    }

    &__item {
      max-width: 220px;
      justify-self: center;
    }

    &__paragraph {
      height: 22px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }

    &__actions {
      margin-top: 20px;
    }

    &__absolute {
      bottom: 20px;
      right: -20px;
    }
  }
}

.article {
  &__logo {
    display: block;
  }
}

.refresh-solution {
  &__text__bar {
    display: flex;
    word-break: keep-all;
    overflow-wrap: break-word;
    grid-column: span 1;
    justify-content: end;
  }
}
