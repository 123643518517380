#confirmation-modal {
  z-index: 9999999;
}

.modal_container {
  z-index: 110;
  width: 100vw;
  height: 0;
  top: 100vh;
  position: fixed;
  visibility: hidden;
  opacity: 0;
  display: block;
  overflow-y: auto;
  overflow-x: hidden;
  background: $bg-standard;

  border-radius: 5px;
  box-shadow: $active-shadow;


  @media screen and (max-width: map_get($breakpoints, medium)) {
    height: 100vh !important; /* see: checkFullPopupModalPosition */
    top: 0 !important; /* see: checkFullPopupModalPosition */
    z-index: 1001;
  }

  &-full_page {
    z-index: 1000;
  }

  .modal {
    background: $bg-transparent;
    margin: 0 auto;
    width: 100vw;
    position: relative;
    z-index: 101;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    min-height: 50vh;

    .transparent-button {
      padding: 0;
    }

    &__header {
      text-align: right;
      flex: 0 1 auto;
    }

    &__content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .media_preview__action_bar__right {
        display: none;
      }
    }

    .document_additional_info {
      margin-top: -39px;

      @media screen and (max-width: map_get($breakpoints, medium)) {
        margin-top: 0;
      }

    }

  }
}

.centered {
  .modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}


.modal-container {
  width: 500px;
  max-height: 90vh;
}

@media screen and (max-width: 680px) {
  .modal-container {
    width: 80vw;
  }
}

.modal-container__title {
  color: $fg-standard;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 20px;
}

.modal-container__footer {
  display: flex;
  justify-content: end;
  gap: 10px;
  border-top: 1px solid $shade-color;
  margin: 30px -30px 0;
  padding: 30px 30px 0;
}

.modal-container__description {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.popup {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.popup .popuptext {
  color: $fg-standard;
  visibility: hidden;
  background-color: $bg-standard;
  text-align: center;
  border-radius: 6px;
  padding: 8px 0;
  position: absolute;
  z-index: 200;
  max-width: 100vw;
  overflow: hidden;
  -webkit-animation: fadeIn 0s;
  animation: fadeIn 0s;
  right: 40px;
  filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.5));
}


.popup .active {
  visibility: visible;
  will-change: filter;

  @media screen and (max-width: map_get($breakpoints, small)) {
    left: -135px;
  }
}

.active.popup-wide {
  left: -135px;
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.popuptext-content {
  display: flex;
  flex-direction: column;

  &__close {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-right: 7px;
    margin-left: 7px;
    margin-bottom: 5px;

    ion-icon {
      margin: 0 !important;
    }
  }

  &__title {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    width: 100%;

    font-weight: $fw-700;

    cursor: move;

    padding: 20px;
    margin: -20px;
  }

  &__logo {
    display: flex;
    padding-left: 20px;

    img {
      max-height: 60px;
    }
  }

  &__text {
    font-weight: $fw-standard;
    font-size: $fz-12;
    line-height: $lh-16;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: start;
    padding: 20px;

    max-height: 1rem;

    overflow-y: auto;

    &__line {
      margin-top: 5px;
      margin-bottom: 5px;
    }

    &__line:first-child {
      margin-bottom: 5px;
    }

    &__line:last-child {
      margin-top: 5px;
    }
  }

}
