.mini_list {

  &__container {

    &__separator {
      border: $standard-border;
    }

    &__header {

      cursor: pointer;
      height: 50px;
      display: flex;
      justify-content: space-between;
      padding: 13px 0;

      &.empty {
        opacity: 0.3;
      }

      &__button {
        ion-icon {
          visibility: visible;
        }
      }

      &__title {

        align-items: center;
        display: flex;

        &__text {
          line-height: $lh-standard;
          font-weight: $fw-700;
          &.expanded {
            color: $primary-color;
          }
          &.expanded, &:hover {
            color: $primary-color;
          }
        }

        &__count {
          margin-left: 8px;
          color: #FFFFFF;
          background: $secondary-color;
          border-radius: 22px;
          min-width: 16px;
          height: 16px;
          padding: 4px 5px 5px;
          align-items: center;
          display: flex;
          justify-content: center;
          flex-direction: row;
          font-size: $fz-10;
        }
      }
    }

    &__links {

      margin-bottom: 7px;

      &__link_row {

        padding: 7px 0 7px 0;
        display: flex;
        justify-content: space-between;
        cursor: pointer;

        &__title {
          font-size: $fz-14;
          line-height: $lh-19;
          font-weight: $fw-standard;
          width: 100%;

          &.paid {
            color: $secondary-color;

            &:hover {
              color: $secondary-color;
              text-decoration: underline;
            }
          }

          &.selected {
            color: $primary-color;
            font-weight: $fw-700;

            .mini_list__container__links__link_row__title__first_line {
              color: $primary-color;
              font-weight: $fw-700;
            }

          }

          &:hover {
            text-decoration: underline;
            color: $primary-color;

            .mini_list__container__links__link_row__title__first_line {
              color: $primary-color;
            }

          }

          &.paid {
            color: $secondary-color;
            &:hover {
              text-decoration: underline;
            }

            .mini_list__container__links__link_row__title__first_line {
              color: $secondary-color;
            }
          }

          .two_line_text {
            display: flex;

            &__img {
              vertical-align: bottom;
              margin-right: 5px;
              margin-top: 2px;
            }

            &__text {
              vertical-align: top;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              line-clamp: 2;
              -webkit-box-orient: vertical;
            }

          }

          .one_line_text {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            line-clamp: 1;
            -webkit-box-orient: vertical;
          }

          img {
            width: 16px;
            height: 16px;
          }

          &__first_line {

            display: flex;
            align-items: center;

            font-weight: $fw-600;
            font-size: $fz-12;
            line-height: $lh-16;
            color: $shade-color;


            &__tiny_icon {
              display: flex;
              margin-right: 5px;
            }

          }

          &__second_line {
            display: flex;
            align-items: center;

            font-size: $lh-14;
            line-height: $lh-19;

            &__category {
              margin-left: 10px;
            }

            &__tiny_icon {
              display: flex;
              margin-right: 5px;
            }

          }

        }

        &__price {
          flex: none;
          order: 1;
          flex-grow: 0;
          height: 20px;
          display: flex;
          margin-left: 10px;
          flex-direction: column;
          align-items: flex-start;
          color: $shade-color;
          font-size: $fz-12;
          line-height: $lh-19;
        }

      }

      &__show_more {
        display: flex;
      }

    }

  }

}
