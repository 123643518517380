.research-purpose-popup {
  background-color: var(--color-block);
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 99999;
  display: grid;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow-y: auto;

  &__main {
    background-color:  var(--background-color);
    color: var(--foreground-color);
    border-radius: 20px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    box-shadow: var(--active-shadow);
    position: relative;
    padding: 30px;
    max-width: 680px;
    width: calc(100vw - 30px);
    height: fit-content;
  }

  &__template {
    display: flex;
    flex-direction: column;
    gap: 20px;
    font-size: 14px;

    .form_element {
      margin-bottom: 0;
    }
  }

  &__title {
    font-size: 16px;
    font-weight: 800;
    margin: 0;
  }

  &__message {
    font-size: 14px;
  }

  &__item {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
    border-top: 1px solid var(--border-light);
    margin: 0 -30px;
    column-gap: 10px;
    padding: 28px 30px 0;
    user-select: none;
  }
}

.research-purpose-popup .button__secondary {
  color: var(--foreground-color) !important;
  background-color: var(--background-color);
  border-radius: 22px;
  width: fit-content;
  justify-content: center;
  font-size: 16px;
  padding: 5px 14px !important;
}

.research-purpose-popup .button__primary {
  color: var(--text-over-primary-color);
  background: var(--primary-color);
  border-radius: 22px;
  width: fit-content;
  justify-content: center;
  font-size: 16px;
  padding: 5px 14px;
}

.research-purpose-popup .button__icon {
  border-radius: 22px;
  justify-content: center;
  color: var(--foreground-color);
  background-color: inherit;
  width: 40px;
  padding-right: 0;
}

.research-purpose-popup .button__icon span {
  font-size: 16px !important;
  margin-left: 0 !important;
}

.research-purpose-popup .button__secondary span, .research-purpose-popup .button__primary span {
  margin-left: 0 !important;
  font-size: 16px !important;
}

.research-purpose-popup .login__options button span:first-child {
  margin-left: 22px !important;
}

