.chart-document-container {
  margin-bottom: 25px;
  white-space: normal;
  >img {
    display: none;
  }
  .chartExtraLegend {
    border: 1px solid #515151;
    border-radius: 5px;
    padding: 5px;
    margin: 10px;
    display: inline-block;

    input[type=checkbox] {
      display: none;
    }
    label img {
      margin-right: 5px;
    }
  }
  >div {
    margin-top: 30px;
  }
  p {
    color: $fg-standard;
    margin-bottom: 5px;
    font-weight: bold
  }
  a {
    font-style: $fs-italic;
  }

  .companyResultsTableWrap {
    white-space: nowrap;
    border: 1px solid #D6D6D6;
    overflow-x: auto;
    width: 100%;
    .companyResultsTable {
      border:0;
      thead td, thead td a{font-weight:700;}
      td{padding:5px;}
      td img{vertical-align: middle;}
      &.gt6 td{font-size: 90%;}
      &.gt10 td{font-size: 80%;}
      tr.notVisible{color:#515151;}
    }
  }

  .companyResultsTabDescriptionLogo {
    display: inline-block;
    width: 140px;
    height: 90px;
    overflow: hidden;
  }

  .companyLegendFullDescription {
    p {
      margin-bottom: 6px;
      color: #243F6F
    }

    ul {
      list-style: none;
      padding-left: 0;
      margin: 0;
    }
  }

  .companyLegendFullDescription_inline {
    p {
      font-weight: bold;
    }
  }

  .companyLegendFullDescriptionLink {
    float: right;
    position: relative;
    margin-top: -30px;
    margin-right: 20px;
  }
}