// -----------------------------------------------------------------------------
// This file contains CSS helper classes.
// -----------------------------------------------------------------------------

ion-icon {
  width: 1em;
  height: 1em;

  font-size: 24px;
  cursor: pointer;
  pointer-events: none;

  &[src]:not([src*="PDF"]) {
    --ionicon-stroke-width: 1px;
  }
}

@mixin iconTemplate {
  width: 24px;
  height: 24px;
  display: block;
  float: left;
}

.trash-outline {
  &-red {
    background: url(/icons/trash-outline-red.svg) center center no-repeat;
    width: 24px;
    height: 24px;
    display: block;
  }
}

.download-outline {
  &-black {
    background: url(/icons/download-outline-black.svg) center center no-repeat;
    width: 24px;
    height: 24px;
    display: block;
  }
}

.filter__item__checkbox .checkbox__icon {
  display: flex;

  &.error * {
    color: $danger-color;
  }
  &.checked * {
    color: $primary-color;
  }
}

.close-outline {
  &-black {
    background: url(/icons/close-outline.svg) left top;
    @include iconTemplate;
  }
}

.search-outline {

  &-black {
    background: url(/icons/search-outline.svg);
    margin-left: 6px;
    width: 18px;
    height: 18px;
    display: block;
    float: left;
  }
}

.bookmark-outline {
  &-black {
    background: url(/icons/bookmark-outline-black.svg);
    @include iconTemplate;
    cursor: pointer;
  }
}

.icon {
  width: 40px;
  height: 40px;
  display: block;
  float: left;
  background-repeat: no-repeat;
  background-position: center center;
  cursor: inherit;

  img {
    cursor: pointer;
  }
}

button.icon:not(.not_implemented) {
  cursor: pointer;
}

.ellipsis-horizontal-outline {
  display: flex;
  justify-content: center;
}

.header__logo {
  &-big {
    grid-area: header-logo;
    width: 700px;

    .main-logo {
      max-height: 190px;
    }

    @media screen and (max-width: map_get($breakpoints, medium)) {
      max-width: calc(100vw - 40px);
    }

    @media screen and (max-width: map_get($breakpoints, small)) {
      min-width: 210px;
    }

  }

  &-small {
    width: 210px;
    margin: -9px 0;

    .main-logo {
      max-height: 65px;
    }

    @media screen and (max-width: map_get($breakpoints, xxs)) {
      width: 170px;
    }
  }
}

.share-outline {
  &-black {
    background: url(/icons/share-outline.svg) left top;
    @include iconTemplate;
    cursor: pointer;
  }
}

.mail-outline {
  &-black {
    background: url(/icons/mail-outline.svg) left top;
    @include iconTemplate;
    cursor: pointer;
  }
}
