#transparentPopUp {
  background: $bg-blocker;
  z-index: 999999;

  .popup_modal_content {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    width: auto;
    margin: auto;
    @media screen and (max-width: map_get($breakpoints, medium)) {
      padding-bottom: 110px;
    }

    & > * {
      background-color: $bg-standard;
      border-radius: 20px;
      box-shadow: $active-shadow;
    }

    & > div {
      padding: 30px;
    }

    & > button {
      display: flex;
      justify-content: center;
      align-items: center;

      width: 40px;
      height: 40px;
      margin-top: 10px;
      margin-bottom: 10px;
    }

  }

}
