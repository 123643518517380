// -----------------------------------------------------------------------------
// This file contains all styles related to the hyperlinks of the site/application.
// --------------------------------------------------------------------------------

.hyperlink {
  color: $primary-color;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }

  &:active {
    color: $primary-dark;
  }
}

.hyperlink-toc, .document-metadata a {
  cursor: pointer;
  text-decoration: none;
  margin-bottom: 6px;
  color: $fg-standard;

  &:visited {
    color: $secondary-color;
  }

  &:hover {
    color: $primary-color;
    text-decoration: underline;
  }

  &:active {
    color: $primary-color;
  }

  &.visited, &.paid {
    color: $secondary-color !important;

    &:hover {
      color: $secondary-color;
      text-decoration: underline;
    }

    &:active {
      color: $secondary-color;
      text-decoration: underline;
    }
  }
}

.hyperlink-menu {
  cursor: pointer;
  text-decoration: none;
  margin-bottom: 6px;
  white-space: nowrap;
  color: $shade-darker;

  ion-icon {
    color: $shade-darker;
  }
  &:hover {
    color: $primary-color;
    text-decoration: underline;
  }

  &:active {
    color: $primary-color;
    text-decoration: underline;
  }

}
