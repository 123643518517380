.settings_menu_container {
  ion-icon {
    visibility: visible;
  }

  .settings_icon {
    display: none;
  }

  .settings_text {
    display: flex;
  }

  .button__secondary {
    display: flex;
  }

  span.settings_text:hover {
    text-decoration: underline;
  }

  @media screen and (max-width: map_get($breakpoints, medium)) {
    .settings_icon {
      display: flex;
      height: 30px;
      width: 30px;
      align-items: center;
      justify-content: center;
    }
    .settings_text {
      display: none;
    }

    .button__secondary {

      .btn-text {
        display: none;
      }
    }
  }

  .button_submenu {
    border-radius: 20px;
    box-shadow: $active-shadow;
    background-color: $bg-standard;
    position: absolute;
    z-index: 10;
    padding: 15px;
    right: 0;
    top: 45px;
    max-width: 360px;
    width: calc(100vw - 30px);
    display: flex;
    flex-direction: column;
    row-gap: 15px;

    @media screen and (max-width: map_get($breakpoints, small)) {
      width: calc(100vw - 70px);
    }

    @media screen and (min-width: 600px) and (max-width: 1000px) {
      width: 250px;
    }

    &__item {

      &__input {
        display: grid;
        grid-template-columns: 1fr 20px 20px;
        column-gap: 10px;

        .form_element {
          margin-bottom: 0;
        }
      }

      &__sort-bar {
        display: grid;

        .form_element {
          margin-bottom: 0;
        }
      }
    }

    .button_line {
      padding: 15px 15px 0 15px;
      margin-left: -15px;
      margin-right: -15px;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-content: center;
      border-top: 1px solid rgba(0, 0, 0, 0.2);

      button {
        margin-left: 20px;
      }
    }

    p {
      font-weight: $fw-700;
      padding: 0;
      margin: 0;
    }

  }

}
