// -----------------------------------------------------------------------------
// This file contains all styles related to the header of the site/application.
// -----------------------------------------------------------------------------


.header-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  align-content: space-between;
  z-index: 10;
  width: 100%;
  background-color: $bg-standard;
  top: 0;
  padding: 10px 20px;

  &__left {
    display: flex;
    justify-content: flex-start;
    margin-right: 20px;

    @media screen and (max-width: map_get($breakpoints, medium)) {
      margin-right: 0;
    }
  }

  &__logo {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
  }

  &__center {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    margin-left: 30px;
    margin-right: 30px;

    @media screen and (max-width: map_get($breakpoints, medium)) {
      order: 4;
      margin: 0;
    }
  }

  &__right {
    display: flex;
    justify-content: flex-end;

  }

  //.login_link {
  //  color: $default-font-color;
  //  text-decoration: none;
  //  &:hover, &:active, &:focus {
  //    text-decoration: underline;
  //  }
  //  &:active {
  //    color: $default-font-color;
  //    font-weight: $bolder-font-weight;
  //  }
  //}

  ion-icon[name="menu-outline"] {
    font-size: 32px;
  }

}


.header {
  position: relative;
  width: 100vw;
  background-color: $bg-standard;
  box-shadow: $standard-shadow;
  padding-top: unset;
  z-index: 110 !important;

  .header-container {
    @media screen and ( max-width: map_get($breakpoints, medium)) {
      flex-wrap: wrap;
      justify-content: space-between;
      row-gap: 10px;
    }
  }

  @media screen and (max-width: map-get($breakpoints, medium)) {
    z-index: 1000 !important;
  }

  &__menu {
    display: flex;
    font-weight: 600;
    width: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;

    img {
      width: 24px;
      height: 24px;
    }

    @media screen and ( max-width: map_get($breakpoints, medium)) {
      p {
        display: none;
      }
    }

  }

  &__extra-line {
    display: none;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 13px;


    &__filter {
      p {
        margin-top: 0;
        margin-bottom: 0;
        margin-right: 5px;
      }
    }

    &__search {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      color: $primary-color;

      &__name {
        padding-bottom: 5px;
      }

      &__toggle {
        margin-left: 5px;
      }

      p {
        margin-top: 0;
        margin-bottom: 0;
      }

    }

    @media screen and (max-width: map_get($breakpoints, medium)) {
      grid-area: header-extra-line;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
  }

  @media screen and (max-width: map_get($breakpoints, small)) {


    &__logo {

    }
  }

  &__user-logo{
    width: 30%;
    margin-left: 40px;
    max-width: 200px;
    max-height: 44px;

    @media screen and (max-width: map_get($breakpoints, small)) {
      margin-left: 10px;
    }

    img {
      max-height: 44px;
      max-width: 200px;
      vertical-align: middle;
    }

  }
}
