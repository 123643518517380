// -----------------------------------------------------------------------------
// This file contains all styles related to the header of the site/application.
// -----------------------------------------------------------------------------


footer {
  //position: relative;
  z-index: 100;

  flex-shrink: 0;
  min-height: 152px;
  background-color: #424242;

  padding: 40px 20px 40px $side-space;

  color: white;

  p, a, li {
    color: white;
  }

  a {
    text-decoration: none;
  }

  .mobile {
    display: none;
  }

  .social-networks {
    font-size: $fz-14;
    line-height: $lh-19;
    display: flex;
    align-items: center;

    img {
      width: 24px;
      height: 24px;
      vertical-align: middle;
      display: inline-block;
      margin: auto;
    }

    a {
      margin-left: 19px;
    }
  }

  .additional-logo {
    font-size: $fz-12;
    line-height: $lh-19;
    display: flex;
    align-items: center;
    margin-left: 20px;

    img {
      height: 23px;
      margin-left: 5px;
      margin-right: 5px;
    }
  }

  .logos {
    display: flex;
  }

  .links {
    margin-top: 20px;
    font-size: $fz-14;
    line-height: $lh-19;

    a {
      margin-right: 17px;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .footer-line {
    display: flex;
    justify-content: space-between;
  }

  .copyright {
    font-size: $fz-12;
    line-height: $lh-19;
    margin-top: 20px;
  }

  .additional-logo-text {
    margin-right: 10px;
  }

  @media screen and (max-width: map_get($breakpoints, medium)) {
    padding: 60px 20px 60px 20px;

    .additional-logo {
      display: none;
    }

    .social-networks {
      display: none;
    }

    .copyright {
      display: none;
    }

    .mobile {
      display: block;

      .additional-logo {
        display: flex;
        margin-left: 0;
        margin-top: 40px;
      }

      .social-networks {
        display: flex;
        margin-top: 30px;
      }

      .copyright {
        display: block;
        margin-top: 50px;
      }
    }

    .links {
      margin-top: 40px;

      a {
        margin-bottom: 20px;
        display: block;
      }
    }

  }

}
