// -----------------------------------------------------------------------------
// This file contains CSS helper classes.
// -----------------------------------------------------------------------------
.myNav {

  z-index: 99999;

  max-width: 100%;
  height: 100%;
  position: fixed;
  //background-color: rgba(22, 22, 22, 0.5);


  .myNavOverlay {
    max-width: 0;
    position: fixed;
    z-index: 101;

    max-height: 100vh;
    background-color: $bg-standard;
    transition: $transition-duration;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;


    .closebtn {
      position: absolute;
      top: 20px;
      right: 45px;
      font-size: 60px;
    }

    &__header {

      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      width: 100%;
      height: 72px;
      margin-bottom: 5px;

    }

    &__toggle {
      margin-top: 6px;
      margin-left: 30px;

    }

    &__text {
      margin-top: 40px;
      margin-left: 10px;
      margin-bottom: 30px;
      padding-bottom: 4px;

      p {
        margin-top: 0;
        margin-bottom: 0;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
      }

    }

    .overlay-content {
      position: relative;
      width: 325px;
      text-align: center;
      margin-top: 20px;
      margin-left: 30px;
      margin-right: 30px;
      padding: 0;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;


      &__element {
        width: 100%;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;



      }

      &__sourcesHeader {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: space-between;
        padding: 0 0 10px 0;
        font-weight: bold;
        font-size: $fz-22;
        text-align: left;

        .sourceFilterArea {

          width: auto;

          .filters {
            padding-left: 0;
            margin-top: 17px;
          }

        }

      }

      &__help {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 10px 0;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;

        &:hover {
          text-decoration: underline;
          color: $shade-dark;
        }


        &__support {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          padding: 10px 0;
          font-weight: bold;
          font-size: $fz-22;
          line-height: 30px;

          p {
            margin: 0;
          }
        }

        p {
          margin: 0;
          color: $shade-dark;
        }

      }

      &__contact {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 10px 0;

        font-weight: normal;
        font-size: 16px;
        line-height: 24px;

        &:hover {
          text-decoration: underline;
          color: $shade-dark;
        }


        p {
          margin: 0;
          color: $shade-dark;
        }

      }

    }
  }
}


.mobile-nav {
  margin: 0;
  padding: 0;
  width: 100%;
  list-style-type: none;

  &__levels {
    position: relative;
  }

  &__level {
    background: $bg-standard;
    outline: none;
    transition: transform 210ms;
  }

  &__heading {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 50px;
    font-weight: bold;
    font-size: $fz-22;
    line-height: 30px;

  }

  &__list {
    margin: 0;
    padding: 0;
    width: 100%;
    list-style-type: none;
  }

  &__item {
    display: block;
    padding: 12px 0;
    margin: 0;

    &__url {

    }
  }

  &__listHeader {
    width: 100%;
    text-align: left;
    font-weight: bold;
    font-size: 22px;

    &:hover, &:active {
      color:$primary-color;
      text-decoration: underline;
    }

  }

  &__link {

    &__button {

      ion-icon {
        color: $fg-standard;
      }

      width: 100%;
      text-align: left;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &:hover, &:active {
        color:$primary-color;
        text-decoration: underline;
      }
    }

  }


  &__btn-up {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 25px 0 20px 24px;
    font-weight: 600;
    &__text {
      padding-left: 10px;
    }
  }

  button: focus-visible {
    outline: 0;
  }
}


.slide-left-leave,
.slide-left-leave-to,
.slide-right-leave,
.slide-right-leave-to {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  visibility: hidden;
}

.slide-left-enter-active {
  animation: slide-in-left $transition-duration;
}

.slide-left-leave-active {
  animation: slide-out-left $transition-duration;
}

.slide-right-enter-active {
  animation: slide-in-right $transition-duration;
}

.slide-right-leave-active {
  animation: slide-out-right $transition-duration;
}

@keyframes slide-in-left {
  0% {
    transform: translate3d(100%, 0, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slide-out-left {
  0% {
    visibility: visible;
  }
  100% {
    transform: translate3d(-100%, 0, 0);
  }
}

@keyframes slide-in-right {
  0% {
    transform: translate3d(-100%, 0, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slide-out-right {
  0% {
    visibility: visible;
  }
  100% {
    transform: translate3d(100%, 0, 0);
  }
}

