.advancedSearch {
  height: calc(100% - 1px);
  position: sticky;
  left: 0;
  transition-duration: $transition-duration;
  background-color: $bg-standard;
  overflow-y: hidden;

  &__body {
    padding: 30px;
    height: calc(100% - 185px);
    overflow-y: auto;
    overscroll-behavior: contain;

    @media screen and (max-width: map_get($breakpoints, medium)) {
      height: calc(100% - 235px);
      max-height: calc(100vh - 254px);
      padding-bottom: 80px;
    }
  }

  &__form {
    padding: 30px;

    select {
      font-family: inherit;
      font-size: inherit;
      line-height: inherit;

      background-image: url(/icons/custom/chevron-down-outline.svg);
      background-repeat: no-repeat;
      background-position: right;

      &.open {
        background-image: url(/icons/custom/chevron-up-outline.svg);
      }

    }


    &__footer {
      margin: 0;
      padding-top: 20px;

      border-top: $light-border;

      display: flex;
      flex-direction: column;
      gap: 8px;
      justify-content: center;
      align-content: center;
      width: 100%;

      button {
        flex: 1;
        width: 100%;
      }
    }


  }

  &__search_button {

    span {
      display: block;
    }
  }

  &__reset_button {
    margin-right: 10px;
  }
}


