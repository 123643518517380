.sourceFilterArea {
  position: relative;
}

.resultBorder {
  /*margin-top: -10px;*/
  padding-top: 10px;
  padding-left: 5px;
  position: absolute;
  top: 52px;
  left: 5px;
  right: 4px;
  border: 1px solid #BDBDBD47;
  border-radius: $border-radius-10;
  background-color: $bg-standard;
  z-index: 200;
  filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.5));
  min-height: 32px;
}