// -----------------------------------------------------------------------------
// When having several themes, this file contains everything related to the default one.
// -----------------------------------------------------------------------------

//switch svg-icons to white
@mixin icons_white {
}

//redefinition of used libraries
@mixin libraries {
  .ccm-root {
    .ccm-modal {

      &.ccm-show {
        background: rgba(0, 0, 0, 0.4);
      }

      &--header, &--body {
        background: $bg-standard;
      }
    }

    .ccm-widget {
      .cover-scrollbar {
        &::before, &::after {
          all: unset;
        }
      }
    }

    .ccm-inner-overflow {
      overflow: hidden;
      color: #b7b7b7;
    }
  }

  .swal2-popup {
    background: $bg-standard;

    .swal2-icon.swal2-warning {
      border-color: white;
      color: $bg-standard;

      &::before {
        color: $fg-standard;
      }
    }

    .swal2-content {
      color: revert;

      p a {
        color: $secondary-color;

        &:hover {
          text-decoration: underline;
          color: $secondary-dark;
        }
      }
    }
  }

  .os-scrollbar-handle {
    background: rgba(255, 255, 255, 0.4) !important;
  }
}

.dark-theme {
  --foreground-color: white; //foreground
  --background-color: #1a1a1a; //background
  --color-block: rgba(0, 0, 0, 0.25);

  //secondaries
  --secondary-color: #7F9ED0;
  --secondary-dark: #4479BE;
  --secondary-darker: #005EB1;
  --secondary-light: #005EB1;

  //shade
  --shade-light: #424242;
  --shade-color: #BDBDBD;
  --shade-dark: #f5f5f5;
  --shade-darker: #f5f5f5;

  //borders
  --border-light: rgba(255, 255, 255, 0.1);

  //buttons
  --btn-secondary-focus: #2b2b2b;

  //shadows
  --active-shadow: 0 0 20px var(--shade-light);
  --standard-shadow: 0 0 5px black;
  --dropdown-shadow: 0px 4px 4px black;

  @include icons_white;

  @include libraries;

  background-color: $bg-standard;
  color: $fg-standard;
}
