// -----------------------------------------------------------------------------
// This file contains CSS helper classes.
// -----------------------------------------------------------------------------

.enter {

  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 10px;

  &__header {
    font-size: $fz-standard;
    line-height: $lh-standard;
    font-weight: $fw-700;
  }

  h2 {
    margin-top: 0;
    margin-bottom: 20px;
    font-size: $fz-standard;
    line-height: $lh-standard;
    font-weight: $fw-700;

    white-space: normal;
  }

  form, ul, li {
    max-width: 100vw;
    //padding-inline-start: 0;
  }

  form {
    margin: 0 auto;
  }

  li {
    display: flex;
    justify-content: space-between;
    list-style-type: none;
    //margin: 5px 0;
  }

  input {
    margin-top: 0;
    margin-bottom: 0;
  }

  .form_element {
    margin-top: 10px;
    margin-bottom: 10px;

    &-error {
      border-color: $danger-color;
    }
  }

  &__error {
    color: $danger-color;
    font-size: $fz-12;
    line-height: $lh-16;
    margin-bottom: 20px;

    &-space_above {
      margin-top: 20px;
    }
  }

  .form_element__label {
    justify-content: flex-start;
  }

  &__form__submit_container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-top: 10px;

    .button__secondary {
      margin-right: 20px;
    }

    &__cancel {
      @media screen and (max-width: map_get($breakpoints, small)) {
        display: none!important;
      }
    }
  }

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .furtherInfo {
    h2 {
      margin-top: 30px;
    }
  }

  .furtherInfo_item {
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: $shade-color;
    align-items: center;
    &:last-child {
      border-bottom: none;
    }
  }

  .furtherInfo_item .label {
    width: 100%;
    margin-left: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .furtherInfo_item ion-icon {
    width: 25px;
  }
  &__section {
    display: flex;
    justify-content: space-between;
    align-content: center;
    margin-top: 30px;

    .mandatory {
      margin-left: auto;
      font-weight: $fw-standard;
      font-size: $fz-12;
      line-height: $lh-16;
      color: $shade-color;
    }
  }
}

.login_form {
  width: calc(min(20rem, (100vw - 84px)));
  @media screen and (min-width: map_get($breakpoints, small)) {
    width: calc(min(28rem, (100vw - 84px)));
  }
  &[aria-label="ebib"] .password_forgotten {
    display: none;
  }
}

.password_forgotten {
  display: block;
  margin-left: auto;
  cursor: pointer;
  user-select: none;
  color: $secondary-color;
  font-weight: $fw-standard;
  width: fit-content;

  &:hover {
    text-decoration: underline;
  }
}
