.radio-genios-style {

  padding-left: 3px;

  input[type=radio] {
    opacity: 0;
    position: absolute;
  }

  input[type=radio], label {
    display: inline-block;
    vertical-align: middle;
    margin: 0 3px;
    cursor: pointer;
  }

  label {
    position: relative;
  }

  input[type=radio] + label:before {
    border-radius: 50%;
    content: '';
    background: $bg-standard;
    border: 1px solid #666;
    display: inline-block;
    vertical-align: middle;
    width: 8px;
    height: 8px;
    padding: 2px;
    margin-right: 5px;
    text-align: center;
    margin-top: -3px;
    outline:none;
  }

  input[type=radio]:checked + label:before {
    background-color: $primary-color;
    border: 1px solid $primary-color;
    box-shadow: inset 0 0 0 2px #fff;
  }

  input[type=radio]:hover + label:before,
  input[type=radio]:focus + label:before {
    box-shadow: inset 0 0 0 2px #fff, 0 0 3px $primary-color;
  }

}