//mostly from https://projects.lukehaas.me/css-loaders/
// loader animation - only shows if contained within element with class loading

.loader {
  //default no style
}

.loading {
  .searchResult__articles {
    opacity: 0;
  }

  .searchResult__summary__articlesFound__count {
    opacity: 0;
    filter: blur(10px);
    transition: opacity 1s ease-in-out, filter 2s ease-in-out;
  }

  .loader,
  .loader:before,
  .loader:after {
    border-radius: 50%;
    width: 2.5em;
    height: 2.5em;
    animation-fill-mode: both;
    animation: load7 1.8s infinite ease-in-out;
  }

  .loader {
    color: $primary-color;
    font-size: 10px;
    margin: 80px auto;
    position: relative;
    text-indent: -9999em;
    transform: translateZ(0);
    animation-delay: -0.16s;
  }

  .loader:before,
  .loader:after {
    content: '';
    position: absolute;
    top: 0;
  }

  .loader:before {
    left: -3.5em;
    animation-delay: -0.32s;
  }

  .loader:after {
    left: 3.5em;
  }

  @keyframes load7 {
    0% {
      box-shadow: 0 2.5em 0 -1.3em;
      opacity: 0;
    }
    80%,
    100% {
      box-shadow: 0 2.5em 0 -1.3em;
      opacity: 1;
    }
    40% {
      opacity: 1;
      box-shadow: 0 2.5em 0 0;
    }
  }

}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

