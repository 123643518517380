
.tooltip {
  position: relative;
  display: inline-block;
  width: 100%;

}

.tooltip-button {
  margin-right: 35px;

  @media screen and (min-width: map-get($breakpoints, small)) {
    width: -moz-fit-content;
    width: fit-content;
    min-width: -webkit-fill-available;
  }
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 100%;
  color: $fg-standard;
  border-radius: 5px;
  background: $bg-standard;
  border: 1px solid $fg-standard;
  padding: 4px 10px;
  font-weight: $fw-standard;
  opacity: 0;

  transition: all 0.1s ease 0.2s;
  position: absolute;
  z-index: 99998;
}

@media (hover: hover) {
  .tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }
}

.tooltip-active .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.tooltip-top::after {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  left: 20px;
  bottom: -6px;

  margin-left: -5px;
  transform: rotate(-135deg);
  background-color: $bg-standard;
  border-top: 1px solid $fg-standard;
  border-left: 1px solid $fg-standard;
}

.tooltip-top {
  bottom: 125%;
  left: 0;
}

.tooltip-top-button {
  bottom: 125%;
  left: 50px;
}

.tooltip-icon {
  width: fit-content;
}

.tooltiptext-button {
  width: auto !important;
  padding: 4px 7px !important;
  font-size: small;
  line-height: normal;
  white-space: normal;
}

.tooltip-top-icon {
  bottom: 100%;
}

.tooltiptext-icon {
  width: auto !important;
  padding: 4px 3px !important;
  font-size: x-small;
  line-height: normal;
  white-space: nowrap;
}