@mixin setDisplay() {
  display: grid;
  grid-template-columns: 75px 1fr;
  gap: 20px;
}

#emailSendForm {
  position: absolute;
  z-index: 100;
  top: 35px;
  right: 10px;
  max-width: 800px;
  width: 470px;
  overflow: auto;
  border-radius: 20px;
  box-shadow: $active-shadow;
  background-color: $bg-standard;

  @media screen and (max-width: map_get($breakpoints, small)) {
    width: calc(100vw - 100px);
  }

  @media screen and (max-width: map_get($breakpoints, xs)) {
    width: calc(100vw - 90px);
  }

  @media screen and (min-width: 600px) and (max-width: 1000px) {
    width: 250px;
  }

  &.right-unset {
    right: unset;

    @media screen and (max-width: map_get($breakpoints, xs)) {
      width: 80vw;
      left: 0;
    }
  }
}


.send_email {
  &__form {
    padding: 30px;
    white-space: normal;
    display: flex;
    flex-direction: column;
    row-gap: 10px;

    &__header {
      font-weight: $fw-700;
    }

    .button__primary {
      &:disabled {
        background-color: $primary-color;
        color: white;
      }
    }

    & > * {
      margin: 0;
      padding: 0;
    }

    .form_element {
      margin-bottom: unset;
    }


    &__line_1 {
      display: flex;
      flex-direction: column;
      gap: 10px;

    }

    &__emailsTo {

      &__input-item {
        display: flex;
        gap: 10px;
        width: 100%;
      }
    }

    &__body {
      display: flex;
      flex-direction: column;
      gap: 10px;
      background-color: $shade-light;
      border-radius: $border-radius-22;
      padding: 20px;
    }

    &__message {

      .form_element__label {
        width: 50px;
      }
    }

    &__privacy {
      font-weight: $fw-standard;
      font-size: $fz-12;
      color: $shade-dark;
    }


    &__submit_container {
      border-top: $standard-border;
      padding-top: 20px;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      margin: 10px -30px 0 -30px;

      .button__primary {
        margin-right: 30px;
      }

      .button__secondary {
        margin-right: 20px;
      }

      &__cancel {
        @media screen and (max-width: map_get($breakpoints, small)) {
          display: none !important;
        }
      }
    }

    &__mandatory {
      font-weight: $fw-standard;
      font-size: $fz-12;
      color: $shade-dark;

      margin: 0 0 0 auto;
      padding: 0;

    }
  }
}



