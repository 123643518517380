.table_of_contents {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;

  &__header {
    display: flex;
    flex-direction: column;
    margin-top: 0;

    &__contents {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: wrap-reverse;
      align-items: center;
      font-weight: bold;
      font-size: $fz-22;
      margin-bottom: 20px;

      div {
        margin-top: 10px;
        margin-right: 10px;
      }

      button {
        margin-top: 15px;
      }
    }

    &__documents {
      display: flex;
      flex-wrap: wrap;
      font-weight: normal;
      font-size: $fz-standard;
      line-height: 26px;
      margin-bottom: 20px;
    }
  }

  &__body {
    display: flex;
    flex-direction: column;

    &__document {
      display: flex;
      flex-direction: row;
      align-content: center;
      justify-content: space-between;

      &__page {
        //white-space: nowrap;
      }

      &__text {
        & > .tooltip > .tooltiptext {
          font-size: $fz-12;

          .tooltip-title {
            font-weight: $fw-600;
          }

          .tooltip-regional {
            color: $shade-dark;
          }
        }
      }

      &__header {
        font-weight: $fw-700;
        font-size: $fz-14;
        line-height: $lh-19;
        margin-bottom: 1em;
      }
    }

    & > .dropdown > .tooltip .dropdown-label {
      .selected-option {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
        display: inline-block;
        font-size: $fz-14;
      }

      .tooltiptext {
        font-size: $fz-12;
      }
    }

  }
}

.document_title_wrapper {
  display: flex;
  flex-direction: column;
  width: 90%;
  font-weight: $fw-600;
  font-size: $fz-14;
  line-height: $lh-16;

  &__regional-text {
    color: $shade-dark;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: $lh-19;
    font-weight: $fw-standard;
  }
}

.result_list_header {
  font-weight: $fw-700;
  font-size: $fz-14;
  line-height: $lh-19;
}
