.left-drawer-container {
  display: flex;
  position: sticky;
  top: 0;

  .left-drawer {
    position: relative;
    display: flex;
    flex-grow: 1;
    background: var(--background-color);
    max-width: 100%;
    flex-direction: column;
    z-index: 8;
    overflow-y: hidden;
    overflow-x: hidden;
    transition: 0.1s;

    .drawer-header {
      display: flex;
      align-items: center;
      padding: 5px 30px 0 30px;

      .drawer-title {
        margin-top: 14px;
        flex-grow: 1;
        font-size: 16px;
        font-weight: 700;
      }

      .collapse-icon,
      .expand-icon {
        cursor: pointer;
        display: flex;
        align-items: center;
        height: 50px;
      }
    }

    .menu-item-wrapper {
      &:hover > .navigation-options,
      .navigation-sub-options.hovered {
        color: var(--primary-color);
        text-decoration: underline;
      }

      .navigation-options {
        margin-left: 30px;
        margin-right: 15px;
        display: flex;
        flex-grow: 1;
        padding: 10px 0;
        align-items: center;
        gap: 10px;
        color: var(--foreground-color);
        cursor: pointer;
        min-width: 0;

        ion-icon {
          margin-left: auto;
          font-weight: 100;
          flex-shrink: 0;

          width: 24px;
          height: 24px;
        }

        &.selected {
          color: var(--primary-color);
          text-decoration: underline;
        }
      }

      .sub-items {
        margin-left: 20px;

        .navigation-sub-options {
          margin: 0 17px 8px 17px;
          display: flex;
          align-items: center;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          color: var(--foreground-color);
          cursor: pointer;

          &.last-sub-item {
            margin-bottom: 8px;
          }

          &.selected {
            color: var(--primary-color);
            text-decoration: underline;
          }
        }
      }
    }

    .back-button {
      margin: 25px auto 20px 30px;
      display: block;
    }
  }

  .collapsed-drawer {
    width: 30px;
    height: 100vh;
    background: var(--background-color);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-top: 10px;

    & ~ .expand-button {
      margin-left: 20px;
    }
  }

  .expand-button {
    position: absolute;
    top: 50%;
    right: -10px;
    z-index: 20;

    background: var(--shade-light);
    border: 1px solid var(--shade-light);
    width: 22px;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: right 0.3s;

    ion-icon {
      height: 18px;
      width: 18px;
      color: var(--shade-dark) !important;
    }

    &:hover {
      background: var(--primary-color) !important;
      border: 1px solid var(--primary-color);

      ion-icon {
        color: var(--text-over-primary-color) !important;
      }
    }
  }

  @media (max-width: 850px) {
    .left-drawer {
      position: relative;
      height: 100vh;
      min-width: 321px;
      width: 321px;
      z-index: 200;
    }
  }
}

@media (hover: hover) {
  .menu-item-wrapper:hover > .navigation-options,
  .navigation-sub-options.hovered {
    color: var(--primary-color);
    text-decoration: underline;
  }
}

@media (pointer: coarse) {
  .menu-item-wrapper:active > .navigation-options,
  .navigation-sub-options:active {
    color: var(--primary-color);
    text-decoration: underline;
  }
}

.nav-link {
  text-decoration: none;
  color: inherit;
  flex-grow: 1;
}
