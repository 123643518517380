.full-document {
  position: fixed;
  top: 0;
  left: 0;
  background-color: $bg-standard;
  z-index: 99998;
  width: 100vw;
  height: 100vh;
  overflow: auto;

  #prevAndNextDocument {
    display: none;
  }
}