// -----------------------------------------------------------------------------
// This file contains CSS helper classes.
// -----------------------------------------------------------------------------


.toggle-enter-active,
.toggle-leave-enter {
  transition: all $transition-duration linear;
}

.toggle-enter,
.toggle-leave-to {
  transform: translateX(-100%);
  transition: all .3s linear;

}

.space_above {
  margin-top: 10px;
}

.flex {
  display: flex;
}


.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.gap-30 {
  gap: 30px;
}

.standard-bold {
  font-weight: $fw-700;
}

.simulate-link {
  cursor: pointer;

  text-decoration: underline;
  width: 100%;
}

.simulate-link.link-color {
  color: var(--secondary-color);
}

.simulate-link.link-color:active {
  color: var(--secondary-darker);
}

.fit_image {
  max-width: 100%;
  max-height: 100%;
}


.popup-arrow-up {
  position: absolute;
  left: 16px;
  top: -15px;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;

  border-bottom: 10px solid $secondary-color;
}

.bookmark-icon {
  display: inline-block !important;
  right: -15px;
  position: relative;
  margin-left: auto;

  button:not([class="vs__clear"]) {
    display: flex !important;
  }
}