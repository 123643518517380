.media_tile_container {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: flex-start;
  flex-wrap: wrap;
  padding-left: 2px;


  .no_background {
    background: none;
  }

  .content-left {
    justify-content: left;
  }
}

.media_tile {
  display: flex;
  flex-direction: column;

  text-align: left;

  width: $tile-width-calc;
  background: $shade-light;
  border-radius: $border-radius-10;
  padding-top: 20px;
  margin: 0 20px 20px 0;
  cursor: pointer;
  position: relative;

  transition: box-shadow 0.3s ease-in-out;

  &__functions, .buy_button, .article__checkbox {
    will-change: transform;
    transition: visibility 0.3s, opacity 0.2s ease-in-out;
    visibility: hidden;
    opacity: 0;
    margin-right: 10px;
  }


  &:hover, &:active, &:focus, &:focus-within {
    box-shadow: $active-shadow;

    .article__functions, .buy_button, .article__checkbox {
      visibility: visible;
      opacity: 1;
    }

    .media-article__text__field_D {
      display: none;
    }

    .media-article__text__actions {

      button[class^='button__']:not([class$="icon"]) {
        display: flex;
      }

      .bookmark {
        display: inline-block;
        right: -15px;
        position: relative;
      }
    }
  }

  &.selected {
    background-color: $secondary-light;

    .article__checkbox {
      visibility: visible;
      opacity: 1;
    }
  }

  &.opened {
    box-shadow: 0 0 0 2px $secondary-color, $active-shadow;
    background-color: $bg-standard;

    .media-article__text__actions {
      button[class^='button__']:not([class$="icon"]) {
        display: none;
      }

      .bookmark {
        display: inline-block;
        right: -15px;
        position: relative;
        margin-left: auto;

        button {
          display: flex !important;
        }
      }

    }

    &:hover, &:active, &:focus, &:focus-within {
      .media-article__text__field_D {
        display: block;
      }
    }
  }

  .article__checkbox__label {
    cursor: pointer;
  }

  .article__checkbox {
    -webkit-appearance: none;
    margin: 0;
    position: absolute;
    background-size: cover;
    left: 15px;
    top: 15px;

    &:checked {
      background-color: $bg-transparent;
      width: 24px;
      height: 24px;
      left: 11px;
      top: 11px;
    }
  }

  &__title {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 800;
    font-size: 16px;
    line-height: 22px;
    height: 44px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  &__source {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
    line-height: 16px;
    color: $shade-dark;
  }

  &__date {
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    /* Shade Dark */
    color: $shade-dark;
  }

  .img-container {
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: space-between;
    width: calc(100% - 40px);
    height: calc((#{$tile-width-calc} - 40px) * 1.4);
    max-height: 245px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    position: relative;

    @media screen and (max-width: map_get($breakpoints, medium)) {
      width: 100%;
      max-width: 100%;
      padding-top: 0;
      height: calc(#{$tile-width-calc} * 1.2);
      max-height: 235px;
    }
  }

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
    margin: auto;
    filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.5));

    //z-index: 1;

    @media screen and (max-width: map_get($breakpoints, medium)) {
      margin-top: 10px;
      height: calc(100% - 10px);
    }
  }

  @media screen and (max-width: map_get($breakpoints, medium)) {
    padding-top: 0;
  }

}

.element {
  input[type=checkbox].article__checkbox {
    display: none;
  }

  .checkbox__icon {
    color: $secondary-color;
    width: 20px;
    height: 20px;

    ion-icon {
      font-size: 20px;
    }
  }

  &.selected .article__checkbox.checkbox__icon.unchecked {
    display: none;
  }

  &:not(.selected) .article__checkbox.checkbox__icon.checked {
    display: none;
  }
}

.media_tile_container-fake_video {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: flex-start;
  flex-wrap: wrap;
  height: 250px;

  .media_tile {
    width: $tile-width-calc;
    height: 250px;
    background: $shade-light;
    border-radius: $border-radius-10;
    padding: 10px;
    margin: 0 10px 10px 0;
    position: relative;

    &.selected {
      box-shadow: 0 0 0 2px $secondary-color, $active-shadow;
    }

    &__title {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: 800;
      font-size: 16px;
      line-height: 22px;
      height: 44px;
      margin-top: 10px;
      margin-bottom: 10px;
    }

    &__source {
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 12px;
      line-height: 16px;
      margin-bottom: 10px;
      color: $shade-dark;
    }

    &__date {
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      /* Shade Dark */
      color: $shade-dark;
    }

    .img-container {
      width: 245px;
      height: 245px;
    }

    img {
      display: block;
      max-width: 245px;
      max-height: 245px;
      width: auto;
      height: auto;
      margin: 0 auto;
      filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.5));
    }
  }

  .no_background {
    background: none;
  }

  .content-left {
    justify-content: left;
  }
}

.wrap-text {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.browse_page {

  .media_tile {
    .article__checkbox {

      @media screen and (max-width: map_get($breakpoints, medium)) {
        visibility: visible;
        opacity: 1;
      }

    }
  }

}