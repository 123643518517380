.watchlist_dialog {
  border-radius: 20px;
  box-shadow: $active-shadow;
  background-color: $bg-standard;
  position: absolute;
  z-index: 120;
  padding: 20px;
  left: 0;
  top: 46px;
  min-width: 425px;
  max-width: 460px;
  white-space: normal;

  @media screen and (max-width: map_get($breakpoints, small)) {
    min-width: 0;
    width: 80vw;
  }

  @media screen and (min-width: 600px) and (max-width: 1000px) {
    width: 250px;
    min-width: 0;

    .delete_dialog {
      min-width: 425px;
      max-width: 460px;
    }
    .delete_dialog p {
      width: 250px;
    }
  }

  &__button_line {
    margin: 0;
    padding-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-content: center;
    width: 100%;
    column-gap: 20px;
  }

  &__header {
    font-weight: $fw-700;
    margin-top: 0;
  }

  &__errorBox {
    color: $danger-dark;
    margin-top: 10px;
  }

  .watchlistSelection {

    width: 100%;
    margin-top: 15px;

    @media screen and (max-width: map_get($breakpoints, small)) {
      width: 100%;
      margin-left: -10px;
    }

    .vs__selected-options {
      white-space: nowrap;
      overflow: hidden;
    }

    .vs__selected {
      width: 100%;
      margin: 0;
      padding: 0;
    }

    .vs__actions {
      padding: 0;
    }

    .vs--single.vs--open .vs__selected {
      position: relative;
    }

    .vs__dropdown-toggle {
      border-radius: 22px;
      height: 44px;
      border: 1px solid #E0E0E0;
      padding: 0 15px;
    }

    .vs__search {
      width: 0;
      height: 0;
      position: absolute;
      top: -10px;
      left: -10px;
    }

    .vs__dropdown-menu {
      padding: 0;
      margin-top: 10px;
      border: none;
      box-shadow: $active-shadow;
      border-radius: 20px;
      overflow: hidden;
    }

    .vs__dropdown-option {
      padding-top: 7px;
      height: 36px;
      color: $fg-standard;
    }

    .vs__dropdown-option--highlight {
      background: $primary-color;
    }

  }


}

.article__functions {
  .watchlist_dialog {
    right: 0;
    left: auto;
    top: -260px;

    @media screen and (max-width: map_get($breakpoints, small)) {
      right: -10px;
      top: -280px;
    }

    &.delete_dialog {
      top: -180px;
    }

    &.add_dialog {
      top: -300px;
    }
  }
}

.media_preview__action_bar__icons {
  .watchlist_dialog {
    right: 4px;
    left: auto;
  }
}

#browse-toolbar{
  .watchlist_dialog {
    right: 0;
    left:unset;
  }
}