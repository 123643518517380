// -----------------------------------------------------------------------------
// This file contains styles that are specific to the home page.
// -----------------------------------------------------------------------------
.main {
  min-height: calc(100vh - 219px);
}

.home {
  &__logo {
    display: flex;
    align-content: center;
    justify-content: center;
    margin-bottom: 20px;
    margin-top: 100px;
  }

  &__search {
    display: flex;
    align-content: center;
    justify-content: center;
    height: 60px;
    margin-bottom: 154px;
    .search {
      .search_layout {
        background-color: $bg-standard;
        border-radius: 30px;
        height: 60px;
        width: 943px;
        min-width: 200px;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        flex-grow: 1;
        z-index: 1;
        padding-left: 10px;

        transition: all 0.5s ease-out 0.5s, background-color 0s;

        &__input {
          flex: none;
          order: 2;
          flex-grow: 1;
          margin: 0 10px;
          background-color: $bg-transparent;

          &__disabled{
            background-color: $bg-standard;
          }
        }

        &__reset {
          flex: none;
          order: 3;
          flex-grow: 0;
          background-size: 26px!important;
        }

        #search_button {
          height: 40px;
          min-width: 40px;
          margin-right: 15px;

          transition: padding 0.5s ease 0.5s, background 0.8s;
          .btn-text {
            transition: max-width 0.5s ease 0.5s;
          }
        }

        @media screen and (max-width: map_get($breakpoints, medium)) {
          &__input {
            order: 0;
          }

          &__reset {
            order: 1;
          }

          &__submit {

            order: 2;

          }

        }

        &:hover {
          border-color: $bg-transparent;
          box-shadow: $active-shadow;
          -webkit-appearance: none;

          transition: all 0.3s ease-out;
        }

      }
    }
  }

  &__corporate_content {
    display: block;
    width: calc(100vw - 60px);
    max-width: 1440px;
    margin-left: auto;
    margin-right: auto;
    border: none;
    overflow: hidden;
    transition: opacity ease 300ms;
    
    @media screen and (max-width: map-get($breakpoints, small)) {
      width: calc(100vw - 30px);
    }
  }
}
