@import "../mixins/index";

.monitoring_form {
  &__form {
    width: calc(min(500px, (100vw - 84px)));;
    max-width: calc(100vw - 84px);
    gap: 30px;
    display: flex;
    flex-direction: column;

    &__top_line {
      display: flex;
      align-items: center;
      gap: 10px;

      ion-icon {
        visibility: visible;
      }

      &__settings_button {
        height: 40px;
        width: 43px;
        border: 1px solid $standard-border;
      }
      > .form_element {
        margin: 0;
      }
    }

    &__summary {
      font-size: $fz-14;
      line-height: normal;
      display: flex;
      align-items: center;
      margin-top: -10px;
      color: $shade-dark;
    }

    &__settings {

      &__monitoring_toggle {
        margin-top: 20px;
        display: flex;
        align-items: center;

        ion-icon {
          margin-right: 10px;

          &[name="checkbox-outline"] {
            color: $primary-color;
          }
        }
      }

      > .form_element {
        margin-bottom: 0;
      }

      > .searchResult__active_filters {
        margin: 0;
      }

      &__checkbox, &__radio {
        margin-top: 20px;
        display: flex;
        align-items: center;
        cursor: pointer;

        ion-icon {
          margin-right: 10px;

          &[name="checkbox-outline"], &[name="radio-button-on-outline"] {
            color: $primary-color;
          }
        }

        input {
          width: 0;
          height: 0;
          padding: 0;
          margin: 0;
          opacity: 0;
        }
      }

      &__schedule {
        margin-top: 20px;
        background-color: $shade-light;
        border-radius: $border-radius-10;
        padding: 20px;

        @include inputAutofill($shade-light, $fg-standard);

        &__emails {
          display: flex;
          align-items: center;

          &__button {
            margin-left: 10px;

            &:disabled {
              cursor: not-allowed;
              color: $shade-color;
            }
          }
        }

        &__day_selector {
          display: flex;
          align-items: flex-start;

          margin-top: 10px;

          &__button {
            width: 32px;
            height: 32px;

            border-radius: 50%;
            border: $standard-border;

            display: flex;
            align-items: center;
            justify-content: center;

            margin-right: 10px;

            &-active {
              background-color: $secondary-color;
              color: $bg-standard;
              border: none;
            }
          }
        }

        &__daily_multiple_text {
          color: $shade-dark;
        }

        &__layout__header {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: flex-start;
        }

        &__hits {
          display: flex;

          label {
            margin-top: 10px;
            margin-right: 20px;
          }
        }

        .dropdown, &__layout__header {
          max-width: 280px;
        }
      }
    }

    &__submit_container {
      border-top: $standard-border;
      margin: 10px -30px 0 -30px;
      padding: 30px 30px 0 30px;
    }
  }

  ion-icon[name="information-circle-outline"] {
    color: $secondary-color;
  }

  &__layout_preview_main_container {
    position: relative;
    z-index: 3;
    width: 430px;
    max-width: calc(100vw - 80px);
    right: -40px;
    top: 9px;
  }

  &__layout_preview {
    position: absolute;
    left:-50px;
    top:-5px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;
    background: $secondary-color;
    border-radius: $border-radius-10;
    padding-bottom: 10px;
    padding-top: 10px;
    padding-left: 20px;

    &__left {
      align-content: flex-start;
      padding-right: 10px;
    }

    &__right {
      align-content: flex-end;
    }

    &__title {
      position: static;
      width: 200px;
      left: 5px;
      top: 0;

      font-family: $font-family;
      font-style: $fs-standard;
      font-weight: $fw-600;
      font-size: $fz-standard;
      line-height: $lh-standard;
      /* identical to box height */
      display: flex;
      align-items: center;
      /* White */
      color: $bg-standard;
      /* Inside auto layout */
      flex: none;
      order: 0;
      flex-grow: 0;
      margin: 5px 0;
    }

    &__description {
      position: static;
      width: 200px;
      left: 5px;
      top: 27px;

      font-family: $font-family;
      font-style: $fs-standard;
      font-weight: $fw-standard;
      font-size: $fz-standard;
      line-height: $lh-standard;

      display: flex;
      align-items: center;
      /* White */
      color: $bg-standard;
      /* Inside auto layout */
      flex: none;
      order: 1;
      flex-grow: 0;
      margin: 5px 0;
    }

    &__image_container {
      margin-top: 10px;
      position: inherit;
      width: 160px;
      height: 90px;
      /* Shade Light */
      background: $shade-light;
    }


    &__fit_image {
      position: center;
      /* top | right | bottom | left */
      margin: 10px 10px 10px 15px;
      width: 125px;
      height: 70px;
    }

    &__info_button {
      position: relative;
    }

    &__arrow {
      position: absolute;
      top: 16px;
    }

    @media screen and (max-width: map_get($breakpoints, xs)) {
      max-width: 220px;
    }
  }
}
