// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------
@import "../mixins/index";

* {
  box-sizing: border-box;
}

html {
  font-family: $font-family;
  font-style: $fs-standard;
  font-weight: $fw-standard;
  font-size: $fz-standard;
  line-height: $lh-standard;
  padding: 0;
  margin: 0;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  overflow: hidden;
  width: -moz-available !important;
  width: -webkit-fill-available !important;
  width: fill-available !important;
}

body {
  margin: 0;
  padding: 0;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  overflow-y: auto;
}

a {
  text-decoration: none;
}

@media screen and (max-width: map-get($breakpoints, xs)) {
  div {
    outline: none;
  }
}

.bodycontainer {
  flex: 1 0 auto;
}

.maincontainer_search {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  min-height: 100vh;
  width: 100vw;
}

.maincontainer {
  min-height: calc(100vh - 120px);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100vw;
}

ul.no-decoration {
  li {
    list-style: none;
  }
}

.with-icon {
  display: flex;
  align-items: center;
  justify-content: flex-start;

}

.with-icon-right {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  img {
    margin-right: 0;
  }
}

.hide {
  display: none !important;
}

.show {
  display: block !important;
}

.min_flex {
  flex-basis: 0;
  flex-grow: 0;
}

.blocker {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  content: ' ';
  background: $bg-blocker;
}

.sticky {
  z-index: 100;
  position: sticky;
  top: 0;
  height: auto;
  align-self: flex-start;
}

.sticky_button {
  position: fixed;
  z-index: 2;
  top: 175px;
  right: 31px;
  border-radius: 20px;
  box-shadow: $active-shadow;
}

.fixed {
  position: fixed;
}

.button-panel {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0;

  button:not(:first-child) {
    margin-left: 10px;
  }

  button:not(:last-child) {
    margin-right: 10px;
  }
}

.count-color {
  color: $shade-dark;
}

.count-color-zero {
  color: $shade-color;
}

.border-line {
  border-bottom: $light-border;
  border-radius: 0;
  margin-top: -2px;
}

.border-line-fix {
  margin-top: 0;
}


.border-line-mobile {
  border-bottom: 3px solid var(--border-light);
  border-radius: 0;
  height: 10px;
  margin-top: -15px;
  width: 20vw;
  margin-left: auto;
  margin-right: auto;
  cursor: ns-resize;
}

.line {
  border: 1px solid $shade-light;
  background-color: $shade-light;

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin: 20px 0;
}

.spacer {
  flex-grow: 10;
}

select {
  appearance: none;
  font-family: inherit;

  cursor: inherit;

  outline: none;

  position: relative;
  background-image: url(/icons/custom/chevron-down-outline.svg);
  background-repeat: no-repeat;
  background-position: right;
  padding-right: 29px;
}

mark.highlight {
  background-color: $primary-light;
  display: inline-block;
}

.not_implemented {
  opacity: 0.5;
}

.checked {
  font-weight: bold;
}

@include inputAutofill($bg-standard, $fg-standard);

.main-logo {
  width: 100%;
}

.audio_player {
  margin-top: 1rem;

  &__length {
    font-size: $fz-14;
    font-weight: $fw-700;
  }
  audio {
    width: 100%;
  }
}

.rotate-0 {
  transform: rotate(0deg);
  transform-origin: center;
  transition: transform 0.25s cubic-bezier(1, -.115, .975, .855);
}

.rotate-180 {
  transform: rotate(180deg);
  transform-origin: center;
  transition: transform 0.25s cubic-bezier(1, -.115, .975, .855);
}

.scroll-to-top {
  position: fixed;
  right: 1rem;
  bottom: 1rem;
  margin: 3rem;
  z-index: 102;
  visibility: hidden;
  opacity: 0;
  background: $shade-dark;
  border:1px solid $shade-dark;

  ion-icon {
    height: 20px;
    width: 20px;
    color: $bg-standard !important;
  }

  @media screen and (max-width: map-get($breakpoints, medium)) {
    right: 0.5rem;
    bottom: 1.5rem;
    margin: 1rem;
  }

  &.visible {
    visibility: visible;
    opacity: 1;
  }
}

.scroll-to-top:hover {
  ion-icon {
    color: $shade-dark !important;
  }
}
