.query-popup {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  max-width: 530px;
  width: calc(100vw - 60px);

  .spinner {
    margin: 0;
  }

  &__title {
    margin: 0;
    font-weight: $fw-700;
    font-size: $fz-standard;
    line-height: $lh-standard;
  }

  .searchResult__pagination {
    padding: 0;

    .pagination {
      margin: 0;
      padding: 0 4px;
    }
  }

  /*css for control article*/

  .article {
    grid-template-columns: 1fr 90px;

    &__img {
      display: none;
    }

    &__functions {
      display: none !important;
    }

    &__text {
      max-width: 500px;
      padding: 0;

      &__source {
        display: none;
      }

      &__bar__number_pages {
        display: none;
      }

      &__bar__actions {
        .buy_button {
          margin-left: 0;
        }
      }
    }

    &__img__source {
      display: flex;

      span {
        font-size: $fz-12;
        color: $shade-dark;
      }
    }

    &__source {
      &__image {
        display: none;
      }
    }

    &:active, &:focus, &:focus-within, &:hover {
      @media screen and (max-width: map-get($breakpoints, "xs")) {
        .buy_button {
          margin-left: 0;
        }
      }
    }
  }
}