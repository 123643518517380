archive-month-picker {
  //reserve space for vue component, so that page does not jump
  height: 94px;
}
.archive_month_picker {
  margin-top: 30px;
  position: relative;

  ion-icon {
    visibility: visible;
  }

  &__picker {
    position: absolute;
    top: 42px;
    z-index: 100;
    background: $bg-standard;
    display: flex;
    flex-direction: column;
    align-items: start;
    width: 100%;
    max-width: 380px;
    padding: 20px;
    border-radius: $border-radius-10;
    box-shadow: $active-shadow;

    &__year {
      width: inherit;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;

      font-size: $fz-22;
      font-weight: $fw-600;

      input[type="number"] {
        font-family: inherit;
        font-size: inherit;
        font-weight: inherit;
        width: 4em;
        border: 0;
      }
    }

    &__months {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      &__month {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 32.5%;
        height: 40px;

        button {
          width: 98%;
        }
      }
    }
  }
}
