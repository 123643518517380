//from https://icons8.com/cssload/en/spinners
.spinner-container {
  display: block;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.spinner{
  display: inline-block;
  position:relative;
  width:37px;
  height:37px;
  margin: auto
}

.circularG{
  position:absolute;
  background-color:$secondary-color;
  width:9px;
  height:9px;
  border-radius:6px;
  animation-name:bounce_circularG;
  animation-duration:0.338s;
  animation-iteration-count:infinite;
  animation-direction:normal;
}

#circularG_1{
  left:0;
  top:15px;
  animation-delay:0.128s;
}

#circularG_2{
  left:4px;
  top:4px;
  animation-delay:0.164s;
}

#circularG_3{
  top:0;
  left:15px;
  animation-delay:0.21s;
}

#circularG_4{
  right:4px;
  top:4px;
  animation-delay:0.256s;
}

#circularG_5{
  right:0;
  top:15px;
  animation-delay:0.292s;
}

#circularG_6{
  right:4px;
  bottom:4px;
  animation-delay:0.338s;
}

#circularG_7{
  left:15px;
  bottom:0;
  animation-delay:0.374s;
}

#circularG_8{
  left:4px;
  bottom:4px;
  animation-delay:0.42s;
}



@keyframes bounce_circularG{
  0%{
    transform:scale(1);
  }

  100%{
    transform:scale(.3);
  }
}

@-o-keyframes bounce_circularG{
  0%{
    -o-transform:scale(1);
  }

  100%{
    -o-transform:scale(.3);
  }
}

@-ms-keyframes bounce_circularG{
  0%{
    -ms-transform:scale(1);
  }

  100%{
    -ms-transform:scale(.3);
  }
}

@-webkit-keyframes bounce_circularG{
  0%{
    -webkit-transform:scale(1);
  }

  100%{
    -webkit-transform:scale(.3);
  }
}

@-moz-keyframes bounce_circularG{
  0%{
    -moz-transform:scale(1);
  }

  100%{
    -moz-transform:scale(.3);
  }
}
