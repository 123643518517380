@import "./../mixins/index";

$maxArticleWidth: 944px;

.document__container, .fido_component_container {
  .document_and_metadata_container {
    display: flex;
    flex-direction: row;

    & > .document {
      border-right: $standard-border;

      @media screen and (max-width: map_get($breakpoints, medium)) {
        border: none;
      }
    }

    .document__overlay {
      //position: absolute;
      //left: 50%;
      //transform: translate(-50%, -30%);
      //
      //max-width: 100vw;
      //
      //z-index: 200;
      //
      //padding: 20px;
      //border-radius: $border-radius-20;
      //box-shadow: $active-shadow;
      //
      //background-color: $bg-standard;

      * {
        white-space: normal;
      }
    }

    @include documentMedata;

    @media screen and (max-width: map_get($breakpoints, large)) {
      margin-left: auto;
      margin-right: auto;
      padding-left: 60px;
    }

    @media screen and (max-width: map_get($breakpoints, medium)) {
      width: -webkit-fill-available;

      flex-direction: column;
      padding-left: 0;
    }
  }
}

.document__container {
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 30px;

  @media screen and (max-width: map-get($breakpoints, xs)) {
    padding: 15px;
  }

  #emailSendForm {
    left: 0;
    top: 45px;
  }
}

.multi_document_container {
  position: relative;
  padding: 30px;
  margin-left: calc(min(max(20px, 50vw - 480px), 250px));
  margin-right: 20px;

  &__overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 10;
    background-color: $bg-standard;
    opacity: 0.5;
  }

  &__title_list {
    margin-left: 5px;
    margin-bottom: 30px;
    max-width: $maxArticleWidth;
    position: relative;

    &--hidden {
      opacity: 0;
    }

    &__header {
      font-size: $fz-22;
      font-weight: $fw-800;
      line-height: $lh-30;
      margin-bottom: 30px;
    }

    &__item {
      font-size: $fz-14;
      line-height: $lh-19;
      margin-bottom: 1em;

      &:last-child {
        margin-bottom: 0;
      }

      a:not([class*='button__']) {
        color: $fg-standard;
        display: block;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        &:hover {
          color: $primary-color;
          text-decoration: underline;
        }

        &:active {
          color: $primary-darker !important;
        }

        &:visited {
          &:hover {
            color: $secondary-color;
          }

          &:active {
            color: $secondary-darker !important;
          }
        }
      }

      &__source {
        display: flex;
        align-items: center;
        color: $shade-color;
        font-size: $fz-12;
        line-height: $lh-16;

        span {
          margin-left: 5px;
        }
      }
    }

    &__show_more_less {
      position: absolute;
      right: 0;
      bottom: 0;

      display: flex;
      align-items: center;
      justify-content: flex-end;

      font-size: $fz-14;
      line-height: $lh-19;
      opacity: 0.4;

      ion-icon {
        visibility: visible;
      }

      span:hover {
        text-decoration: underline;
      }

      @media screen and (max-width: map_get($breakpoints, xs)) {
        position: unset;
        width: 100%;
      }
    }
  }

  &__single_article {
    border-top: 1px solid $shade-color;
    padding-top: 30px;
    margin-bottom: 30px;
    max-width: $maxArticleWidth;

    .document__container {
      padding: 0;

      .document_and_metadata_container {
        margin: 0;
        padding: 0;

        .document {
          //removes border between right and left side of document view for mult-doc as the right side has been removed
          border-right: none !important;
          width: 100%;

          .with-fixed-size {
            padding: 0;
            margin: 0;
            width: auto;
            max-width: $maxArticleWidth;
          }

          .document_additional_info {
            margin-top: 0;
          }

          .table-responsive {
            border-bottom: 1px solid var(--shade-light);
          }
        }
      }
    }

    @media screen and (max-width: map_get($breakpoints, small)) {
      margin-bottom: 15px;
    }
  }

  @media screen and (max-width: map_get($breakpoints, small)) {
    padding: 5px;
  }
}

.document__navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;

  button {
    flex-grow: 0;
  }

  .grow {
    flex-grow: 1;
  }
}

.document {
  font-style: $fs-standard;
  font-weight: $fw-standard;
  font-size: $fz-standard;
  line-height: $lh-standard;
  font-family: $font-family;

  .with-fixed-size {
    position: relative;
    left: 0;
    right: 0;

    max-width: 720px;
    width: 720px;
    margin-left: 235px;
    padding-right: 25px;
    white-space: nowrap;

    word-break: break-word;
    word-wrap: break-word;
    hyphens: auto;

    @media screen and (max-width: map_get($breakpoints, large)) {
      width: auto;
      margin-left: 0;
      //margin-right: 20px;
    }

    @media screen and (max-width: map_get($breakpoints, medium)) {
      width: auto;
      margin-left: 0;
      //margin-right: 20px;
    }

    @media screen and (max-width: map_get($breakpoints, small)) {
      max-width: 100%;
    }

    @media screen and (max-width: map_get($breakpoints, xs)) {
      padding-right: 0;
    }

    #content {
      @media screen and (max-width: map_get($breakpoints, xs)) {
        line-height: 28px;
      }

      p span span {
        white-space: break-spaces;
      }
    }
  }

  &__preview {
    white-space: normal;

    &__source {
      display: flex;
      font-weight: normal;
      font-size: $fz-12;
      line-height: $lh-16;
      color: $shade-dark;
      flex: none;
      order: 0;
      align-self: stretch;
      flex-grow: 0;
      margin: 5px 0;
    }

    &__supertitle {
      color: $primary-darker;
    }

    &__title {
      margin: 5px 0;
      font-weight: 800;
      font-size: $fz-22;
      line-height: 30px;
    }

    &__subtitle {
      margin: 5px 0;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      color: $shade-darker;
    }

    &__trefferumgebung {
      margin-top: 5px;
      color: $shade-dark;
    }

  }

  &__ugly_text {
    white-space: normal;
    filter: blur(4px);
  }

  h1 {
    line-height: normal;
  }

  pre {
    margin-top: 5px;
    margin-bottom: 5px;
    white-space: pre-wrap;
    font-family: $font-family;

    &:first-of-type {
      font-size: $fz-standard;
      margin-top: 24px;
      vertical-align: bottom;
      color: $fg-standard;
    }

    &.textCompact {
      font-size: $fz-standard;
      font-weight: normal;
    }

    &.text {
      font-size: $fz-standard;
      font-weight: normal;
    }

    &.boxed {
      font-size: $fz-standard;
      padding: 15px 19px;
      border: $standard-border;
      margin-bottom: 23px;
      background-color: $shade-light;
      margin-top: 15px;
    }
  }

  pre.preTable {
    white-space: pre !important;
    font-family: monospace !important;
  }

  .help {
    float: left;
  }

  .moduleDocumentGraphic {
    display: flex;
    flex-wrap: wrap;

    img {
      margin-bottom: 10px;
    }

    img:last-of-type {
      margin-bottom: 0;
    }
  }

  img {
    max-width: 100%;
    height: auto;
  }

  .boxLogo {
    display: none;
  }

  .gray {
    color: $gray;
  }

  b, .bold {
    font-weight: $fw-700;
  }

  .boldLarge, span .boldLarge {
    font-style: $fs-standard;
    font-weight: $fw-700;
    font-size: $fz-26;
    line-height: $lh-30;
    white-space: normal;
    margin: 0 0 10px 0;
  }

  .boldLarge + .boldLarge {
    margin: 32px 0 10px 0;
  }

  .italic {
    font-style: $fs-italic;
    color: $gray;
  }

  em.hlt1 {
    background-color: var(--primary-light);
    font-style: inherit;
  }

  ul {
    margin: 0;
  }

  ol, ul {
    padding: 0;
    margin-left: 18px;
    margin-top: 5px;
    word-wrap: normal;
    white-space: normal;
    width: auto;
  }

  ul > li, ol > li {
    list-style-position: inherit;
  }

  td {
    padding-left: 5px;
  }

  .doc-image {
    text-align: center;
  }

  .doc-image-text {
    display: block;
    white-space: normal;
    margin-top: 10px;
    font-style: italic;
  }

  .doc-align.center {
    text-align: center;
  }

  .doc-align.left {
    text-align: left;
  }

  .doc-align.right {
    text-align: right;
  }

  small {
    font-size: 80%;
  }

  .doc-box, .doc-code {
    padding: 15px 19px;
    border: 1px solid #d6d6d6;
    margin-bottom: 23px;
    background-color: #efefef;
  }

  .doc-code {
    font-family: "Courier New", Courier, monospace;
  }

  .sub-header {
    margin-top: 10px;

    &.h-1 {
      font-size: 130%;
    }

    &.h-2 {
      font-size: 125%;
      font-style: italic;
    }

    &.h-3 {
      font-size: 120%;
    }

    &.h-4 {
      font-size: 110%;
      font-style: italic;
    }

    &.h-5 {
      font-size: 105%;
    }

    &.h-6 {
      font-size: 100%;
      font-style: italic;
    }
  }

  .document_additional_info {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .action_icons {
    flex: 1;
    display: flex;
    flex-wrap: nowrap;

    .not_paid {
      opacity: 0.5;
    }

  }

  .attachment_buttons {
    display: flex;
    align-items: center;
  }

  #content {
    button, a.button__primary, a.button__secondary {
      margin-top: 20px;
      margin-bottom: 36px;
      margin-right: 20px;
      display: inline-flex;
    }
  }

  .ebook_buttons {
    flex: 1;
    display: flex;
    align-items: center;
    margin-top: 10px;

    button {
      margin-right: 10px;
    }
  }

  .remoteAgency {
    &_mobile-title {
      display: none;

      @media screen and (max-width: map-get($breakpoints, "xs")) {
        display: block;
        font-size: 18px;
      }
    }

    &_buttons {
      flex: 1;
      display: flex;
      align-items: center;
      margin-top: 10px;

      @media screen and (max-width: map-get($breakpoints, "xs")) {
        flex-direction: column;
        align-items: flex-start;

        border-top: 1px solid $shade-color;

        padding-top: 10px;
      }

      .btn-mobile-title {
        display: none;

        @media screen and (max-width: map-get($breakpoints, "xs")) {
          display: block;
        }
      }

      .btn-desktop-title {
        @media screen and (max-width: map-get($breakpoints, "xs")) {
          display: none;
        }
      }

      &_mobile-title {
        display: none;

        @media screen and (max-width: map-get($breakpoints, "xs")) {
          display: block;
        }
      }

      .button_title {
        .paid {
          .content-unpaid {
            display: none;
          }
        }

        .unpaid {
          .content-paid {
            display: none;
          }
        }
      }
    }
  }

  .boldMedium {
    font-size: 18px;
    font-weight: 800;
    margin: 0;
    padding-top: 20px;
  }

  .address {
    margin-bottom: 10px;
  }

  a.footnoteRef, a.footnote {
    text-decoration: none;
    vertical-align: super;
  }

  .table-responsive {
    border-bottom: $standard-border;

    pre {
      font-size: $fz-standard;
      margin-top: 0;
      white-space: pre-wrap;
    }

    .moduleDocumentTable {
      padding-top: 10px;
      padding-bottom: 20px;
      border-spacing: 0.5rem 0;
      white-space: normal;
      width: 100%;

      td {
        padding-left: 0;
        padding-top: 10px;
        font-size: 14px;
        vertical-align: top;

        &.boxFirst {
          font-weight: 700;

          a {
            font-weight: normal;
            display: block;
          }

        }


        pre.text {
          margin: 0;
        }

        @media screen and (max-width: map_get($breakpoints, small)) {
          display: flex;
          flex-direction: column;
          padding-top: 0;
        }

      }

      td:first-of-type {
        width: 25%;
        @media screen and (max-width: map_get($breakpoints, small)) {
          padding-top: 10px;
          width: 100%;
        }
      }
    }

    @media screen and (max-width: map_get($breakpoints, small)) {
      display: flex;
    }
  }

  .rawHtml {
    white-space: pre-wrap;
  }

  .moduleDocumentRights {
    white-space: normal;
  }

  a:not([class*='button__']) {
    color: $primary-color;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }

    &:active {
      color: $primary-darker !important;
    }
  }

}
