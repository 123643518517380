// -----------------------------------------------------------------------------
// This file contains all @font-face declarations, if any.
// -----------------------------------------------------------------------------

/*
@font-face {
  font-family: 'Open-Sans';
  src:   url( '/fonts/opensans-bold.woff2') format('woff2');
  font-weight: bold; //700
  font-display: swap;
}

@font-face {
  font-family: 'Open-Sans';
  src:   url( '/fonts/opensans-regular.woff2') format('woff2');
  font-weight: normal; //400
  font-display: swap;
}
*/

/* open-sans-regular - latin-ext */
@font-face {
  font-family: 'Open-Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local(''),
  url('/fonts/open-sans-v20-latin-ext-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('/fonts/open-sans-v20-latin-ext-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* open-sans-600 - latin-ext */
@font-face {
  font-family: 'Open-Sans';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local(''),
  url('/fonts/open-sans-v20-latin-ext-600.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('/fonts/open-sans-v20-latin-ext-600.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* open-sans-italic - latin-ext */
@font-face {
  font-family: 'Open-Sans';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: local(''),
  url('/fonts/open-sans-v20-latin-ext-italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('/fonts/open-sans-v20-latin-ext-italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* open-sans-600italic - latin-ext */
@font-face {
  font-family: 'Open-Sans';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: local(''),
  url('/fonts/open-sans-v20-latin-ext-600italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('/fonts/open-sans-v20-latin-ext-600italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* open-sans-700 - latin-ext */
@font-face {
  font-family: 'Open-Sans';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local(''),
  url('/fonts/open-sans-v20-latin-ext-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('/fonts/open-sans-v20-latin-ext-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* open-sans-700italic - latin-ext */
@font-face {
  font-family: 'Open-Sans';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: local(''),
  url('/fonts/open-sans-v20-latin-ext-700italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('/fonts/open-sans-v20-latin-ext-700italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* open-sans-800 - latin-ext */
@font-face {
  font-family: 'Open-Sans';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: local(''),
  url('/fonts/open-sans-v20-latin-ext-800.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('/fonts/open-sans-v20-latin-ext-800.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* open-sans-800italic - latin-ext */
@font-face {
  font-family: 'Open-Sans';
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: local(''),
  url('/fonts/open-sans-v20-latin-ext-800italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('/fonts/open-sans-v20-latin-ext-800italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
