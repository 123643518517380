.test_page {
  margin: 60px;

  h1 {
    font-size: $fz-22;
    font-weight: $fw-800;
    line-height: $lh-30;
  }

  h2 {
    font-weight: $fw-600;;
    font-size: 18px;
  }

  .bold {
    font-weight: $fw-700;
  }

  &__button_box {
    display: flex;
    margin: 20px;
    padding: 5px;
    border: var(--shade-color) solid 1pt;
    border-radius: 30px;

    button {
      margin-right: 5px;
    }
  }

  &__checkbox {
    display: flex;

    ion-icon {
      color: $primary-color;
    }
  }

  .error-test {

    margin: 20px;
    padding: 5px;
    border: var(--shade-color) solid 1pt;
    border-radius: 30px;
    &__line{
      display: flex;
      flex-direction: row;
      align-content: stretch;
      justify-content: space-around;
      align-items: center;
      flex-wrap: wrap;
      .error-color{
        border-radius: 5px;
        border: var(--danger-color) solid 1pt;
        color: $danger-color!important;
      }
      .error-dark {
        border-radius: 5px;
        border: var(--danger-dark) solid 1pt;
        color: $danger-dark!important;
      }

      .error-darker {
        border-radius: 5px;
        border: var(--danger-darker) solid 1pt;
        color: $danger-darker!important;
      }
    }
  }


}

