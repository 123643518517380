@mixin btnPrimary {
  all: unset;
  padding: 5px 14px;
  display: flex;
  width: fit-content;
  border: 1px solid $primary-color;
  border-radius: $border-radius-22;
  background-color: $primary-color;
  color: white;
  cursor: pointer;
  transition: background-color 0.2s ease;

  &:hover {
    border-color: $primary-dark;
    background-color: $primary-dark;
  }

  &:active {
    border-color: $primary-darker;
    background-color: $primary-darker;
  }
}

@mixin darkMode {
  .button__icon {
    ion-icon[name="close-outline"] {
      background-image: url(/icons/close-outline-white.svg) !important;
    }
  }

  select {
    background-image: url(/icons/custom/chevron-down-outline-white.svg) !important;

    &:focus {
      background-image: url(/icons/custom/chevron-up-outline-white.svg) !important;
    }
  }

  .ui-dialog-titlebar {
    .ui-dialog-titlebar-close.spritesIcons {
      background-image: url(/icons/close-outline-white.svg) !important;
    }
  }
}

.demeter {
  all: unset;
  background-color: $bg-standard;
  color: $fg-standard;

  div[class^="boxCorner"] {
    display: none;
  }

  br {
    display: none;
  }

  .spritesIcons {
    display: none;
  }

  #outer {
    all: unset;

    .gridHomeOuter, .gridNoFrame {
      all: unset;
      width: 100%;

      #content {

        .button__icon {
          margin-left: auto;
          margin-bottom: 10px;
          box-shadow: $active-shadow;

          ion-icon[name="close-outline"] {
            background: url(/icons/close-outline.svg) left top, no-repeat;
            height: 24px;
            width: 24px;
          }
        }

        .gridHomeLeft {
          all: unset;
          width: fit-content;

          .content:not(.os-host) {
            overflow-y: auto !important;
          }

          .content {
            position: fixed;
            top: 0;
            left: 0;
            width: 100vw;
            height: 100vh;

            .divColoredCorner {
              background: none;
              width: calc(100vw - 30px);
              max-width: fit-content;
              margin: 0 auto;
              padding: 0;

              .midHeader, .orderOptionBox {
                display: none;
              }

              select {
                border-radius: 22px;
                border-color: $shade-color;
                padding: 0 15px;
                background-color: $bg-standard;
                color: $fg-standard;
                height: 42px;
                width: 100%;

                -webkit-appearance: none;
                -moz-appearance: none;
                box-sizing: border-box;
                background-position: calc(100% - 10px) calc(1em + -8px), calc(100% - 15px) calc(1em + 2px), 100% 0;
                background-repeat: no-repeat;
                background-image: url(/icons/custom/chevron-down-outline.svg);

                &:focus {
                  box-shadow: $active-shadow;
                  border-color: $bg-transparent;
                  background-image: url(/icons/custom/chevron-up-outline.svg);
                }
              }

              input[type="text"], input[type="password"] {
                width: 100%;
                display: block;
                border-radius: $border-radius-22;
                padding: 0 15px;
                height: 42px;
                border: 1px solid $shade-color;
                background-color: $bg-standard;
                color: $fg-standard;

                &:focus {
                  box-shadow: $active-shadow;
                  border-color: $bg-transparent;
                  background-color: $bg-standard;
                }
              }

              .PasswordForgottenForm {
                background: $bg-standard;
                padding: 30px;
                border-radius: 22px;
                box-shadow: $active-shadow;
                max-width: 480px;

                .moduleLayerForgotPassword {
                  width: 100%;
                }

                input[type="text"] {
                  margin: 10px 0;
                }

                .layerTitle, .errorMessage {
                  width: 100% !important;
                  font-size: $fz-standard;
                  font-weight: $fw-700;
                }

                .faz-dialog {
                  .boxIntro {
                    width: 100% !important;
                    font-size: $fz-standard;
                    font-weight: 400;
                    line-height: 22px;

                    .formLabel {
                      width: unset;
                    }
                  }

                  .boxRow {
                    .formLabel {
                      width: 100% !important;
                    }
                  }

                  .boxRowSubmit {
                    .floatRight {
                      display: flex;
                      column-gap: 10px;

                      #f_c0 {
                        display: none;
                      }

                      a {
                        display: none;
                      }

                      .innerModuleButtonSubmit {
                        all: unset;

                        .boxOuter {
                          all: unset;

                          span {
                            @include btnPrimary;
                          }
                        }
                      }
                    }
                  }
                }
              }

              .UserRegisterForm {
                background: $bg-standard;
                padding: 30px;
                border-radius: 22px;
                box-shadow: $active-shadow;
                max-width: 750px;

                .divLayer {
                  width: 100% !important;
                }

                .register-privacyPolicy, .register-withdrawal, .registerNewsLetter {
                  width: 100% !important;
                }

                .errorMessage {
                  width: 100% !important;
                  font-size: $fz-14;
                  font-weight: $fw-700;
                }

                .register-bullets {
                  a {
                    color: $primary-color;

                    &:hover {
                      color: $primary-dark
                    }

                    &:active {
                      color: $primary-darker;
                    }
                  }
                }

                .orderOptionBox {

                  .orderOption {
                    input {
                      float: unset !important;
                    }

                    table {
                      td {
                        vertical-align: top;

                        .formRadio {
                          margin-top: 4px;
                        }
                      }
                    }
                  }
                }

                .registerUserData {
                  padding: 0;

                  .register-privacyPolicy {
                    a {
                      color: $primary-color;

                      &:hover {
                        color: $primary-dark
                      }

                      &:active {
                        color: $primary-darker;
                      }
                    }
                  }

                  .creditCardCompanyInput {
                    display: flex;
                    width: 100%;

                    .valid {
                      margin-right: 0 !important;
                    }

                    #iconForCreditCardCompanyDepartment {
                      display: block !important;
                    }

                    input {
                      width: 100% !important;
                    }
                  }

                  .registerFirstLastInput {
                    display: flex;

                    .valid {
                      margin: 0 !important;
                    }

                    @media screen and (max-width: map-get($breakpoints, small)) {
                      display: grid;
                      grid-template-columns: 1fr 20px 1fr 20px;
                      row-gap: 10px;

                      select {
                        grid-area: 1 / 1 / 2 / 6;
                        display: block;
                        width: calc(100% - 20px);
                      }

                      input {
                        width: 100% !important;
                      }
                    }
                  }

                  .registerStreetInput {
                    display: flex;

                    input:first-child {
                      width: 100% !important;
                    }

                    #f_mainAddress_houseNumber {
                      margin-left: 0 !important;
                    }
                  }

                  .registerCityInput {
                    display: flex;

                    input:first-child {
                      width: 82px !important;
                    }

                    select {
                      margin-right: 20px;
                      grid-column: 1 / 4;
                    }

                    @media screen and (max-width: map-get($breakpoints, small)) {
                      display: grid;
                      grid-template-columns: 80px 20px 1fr 20px;
                      row-gap: 10px;

                      .valid {
                        padding: 0 6px;
                      }
                    }
                  }

                  .registration-border {

                    div div {
                      &:last-child {
                        display: flex;

                        input[type="text"] {
                          width: 100% !important;
                        }
                      }
                    }
                  }

                  .registerEmailInput {
                    display: flex;
                  }

                  .registerPasswordInput {
                    display: flex;

                    input:first-child {
                      margin-right: 10px;
                    }
                  }

                  .creditCardForm {
                    margin: 0;

                    .creditCardUserMonthYear {
                      display: flex;
                      width: 100%;
                      padding: 0;

                      input {
                        width: 100% !important;

                        &:first-child {
                          margin-right: 20px;
                        }
                      }
                    }

                    .creditCardNo {
                      width: 50%;
                      display: flex;

                      input {
                        width: 100% !important;
                      }
                    }

                    #registerCreditCardUserMonthYear {
                      width: 50% !important;
                      white-space: nowrap;
                      align-items: center;
                      column-gap: 5px;
                      padding-right: 20px;

                      select {
                        width: 100% !important;
                      }
                    }

                    @media screen and (max-width: map-get($breakpoints, small)) {
                      display: flex;
                      flex-direction: column;

                      .creditCardNo {
                        width: 100%;
                      }

                      #registerCreditCardUserMonthYear {
                        width: 100% !important;
                        display: grid;
                        grid-template-columns: 1fr 1fr;
                        margin: 0;

                        select:first-child {
                          grid-row: 2;
                          grid-column: 1;
                        }

                        select:last-child {
                          grid-row: 2;
                        }
                      }
                    }
                  }

                  .creditCardValidation {
                    height: fit-content;

                    .creditNumberValidation {
                      display: flex;
                      align-items: center;
                      column-gap: 5px;

                      @media screen and (max-width: map-get($breakpoints, small)) {
                        margin-top: 10px;

                        .valid {
                          margin-left: 0 !important;
                        }
                      }

                      input {
                        width: 70px !important;
                      }

                      .creditCardIcon {
                        position: relative;
                        width: 100%;
                        left: unset;
                      }
                    }
                  }

                  .register-ueberweisung, .register-creditCardUserName {
                    display: flex;
                  }

                  .register-creditCardNo {
                    display: flex;

                    input {
                      width: 100% !important;
                    }
                  }

                  .infoBox {
                    text-align: right;
                  }

                  @media screen and (max-width: map-get($breakpoints, xs)) {
                    .paymentSelection {
                      display: grid;
                      grid-template-columns: 13px 1fr;
                      column-gap: 10px;
                      align-items: center;

                      span {
                        margin: 0 !important;
                      }
                    }
                  }
                }

                .registerButton {
                  .innerModuleButtonSubmit {
                    all: unset;

                    .boxOuter {
                      all: unset;

                      span {
                        @include btnPrimary;
                        margin-left: auto;

                        @media screen and (max-width: map-get($breakpoints, small)) {
                          font-size: $fz-14;
                        }
                      }
                    }
                  }
                }
              }

              .SetPasswordForm {
                border-radius: $border-radius-22;
                box-shadow: $active-shadow;
                padding: 30px;

                .layer_set_password {
                  width: 100%;
                  display: flex;
                  flex-direction: column;
                  row-gap: 10px;

                  p {
                    width: 100%;
                    display: none;

                    &.layerTitle {
                      display: block;
                      font-size: $fz-standard;
                      font-weight: $fw-700;
                    }

                    &.error {
                      display: block;
                    }
                  }
                }

                .errorMessage.error {
                  width: 100% !important;
                  color: $danger-color;
                }

                .errorMessage {
                  br {
                    display: block;
                  }
                }

                .boxRow {
                  .floatLeft {
                    width: 100%;
                  }

                  &.error {
                    all: unset;

                    label {
                      color: $fg-standard !important;
                    }

                    input {
                      border-color: $danger-color;
                    }
                  }
                }

                .innerModuleButtonSubmit {
                  all: unset;

                  .boxOuter {
                    all: unset;

                    span {
                      @include btnPrimary;
                      margin-left: auto;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &.dark-theme {
    @include darkMode;
  }
}

.ConfirmBox_popup {
  background-color: $bg-standard;
  color: $fg-standard;
  border-radius: $border-radius-22;
  padding: 30px;

  .ConfirmBox {
    .moduleLayerRegisterConfirm {
      width: 100%;
      font-size: 14px;

      .innerModuleButtonSubmit {
        all: unset;

        .boxOuter {
          all: unset;

          span {
            @include btnPrimary;
            margin-left: auto;
          }
        }
      }
    }

    .boxIntro {
      width: 100%;

      br {
        display: block;
      }
    }
  }

  .spritesIcons {
    display: block;
  }

  .ui-dialog-titlebar {
    background-color: $bg-standard;
    border-radius: $border-radius-22;
    color: $fg-standard;


    .ui-dialog-titlebar-close.spritesIcons {
      background: url(/icons/close-outline.svg) left top;
      height: 24px;
      width: 24px;
    }
  }

  &.dark-theme {
    @include darkMode;
  }
}