.extra_navigation_line {
  margin-top: 20px;
  width: 100%;
  height: 36px; //prevent jumping while loading

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;

  &__right {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-right: 20px;
  }

  &__button {
    margin-right: 34px;

    span {
      margin-left: 5px;
      line-height: 21px;
    }
  }

  @media screen and (max-width: map_get($breakpoints, medium)) {
    #extra_navigation_line_filter {
      display: flex;
    }
    #extra_navigation_line_advanced_search {
      display: flex;
    }
  }
}
