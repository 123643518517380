@mixin tooltip($title) {
  &[#{$title}] {
    position: relative;

    &:hover::after, &:hover::before {
      opacity: 1;
      transition: all 0.1s ease 0.2s;
      visibility: visible;
    }

    &::before {
      content: '';
      position: absolute;
      width: 10px;
      height: 10px;
      top: -12px;
      left: 15px;
      transform: rotate(-135deg);
      background-color: $bg-standard;
      border-top: 1px solid $fg-standard;
      border-left: 1px solid $fg-standard;
      opacity: 0;
      z-index: 99999;
      visibility: hidden;
    }

    &::after {
      content: attr(data-title);
      top: -140%;
      position: absolute;
      margin-top: 2px;
      font-size: 12px;
      font-weight: $fw-standard;
      padding: 4px 10px;
      color: $fg-standard;
      border-radius: 5px;
      background: $bg-standard;
      border: 1px solid $fg-standard;
      opacity: 0;
      z-index: 99998;
      visibility: hidden;
    }
  }
}

@mixin inputAutofill($bg, $color, $important:false) {
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus {
    @if ($important) {
      -webkit-text-fill-color: $color !important;
      -webkit-box-shadow: 0 0 0 1000px $bg inset !important;
    } @else {
      -webkit-text-fill-color: $color;
      -webkit-box-shadow: 0 0 0 1000px $bg inset;
    }
  }
}

@mixin documentMedata(){
  .document-metadata {
    width: 520px;
    padding: 0 30px 30px;
    font-size: $fz-14;
    line-height: $lh-19;

    .media_preview__main {
      min-width: auto;
      max-width: none;
      padding-bottom: 0;

      .media_preview__further_info__metadata {
        display: none;
      }
    }

    .boldLarge {
      font-style: $fs-standard;
      font-weight: $fw-700;
      font-size: $lh-standard;
      line-height: $lh-30;
      white-space: normal;
    }

    table {
      border-spacing: 0;

      @media screen and (max-width: map-get($breakpoints, xs)) {
        display: block;
        overflow: auto;
      }

      tr {
        td.boxFirst {
          font-weight: $fw-700;
        }

        td:first-child {
          padding-right: 20px;
          white-space: nowrap;
        }

        td {
          vertical-align: top;
        }
      }
    }

    .article__source__image {
      max-width: 100px;
    }

    @media screen and (max-width: map_get($breakpoints, medium)) {
      width: auto;
      margin-left: 0;
      margin-right: 20px;
    }

    @media screen and (max-width: map_get($breakpoints, xs)) {
      margin: 0;
      padding: 0;
    }
  }
}

@mixin animationFlipList() {
  .flip-list-enter-active, .flip-list-leave-active {
    transition: all $transition-duration ease-out;
  }

  .flip-list-enter, .flip-list-leave-to /* .list-leave-active below version 2.1.8 */
  {
    opacity: 0;
    max-height: 0;
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
  }

  .flip-list-move {
    transition: transform $transition-duration ease-in-out;
  }
}

@mixin animationGrow() {
  .grow-enter-active, .grow-leave-active {
    transition: all $transition-duration ease-out;
  }

  .grow-enter, .grow-leave-to /* .fade-leave-active below version 2.1.8 */
  {
    opacity: 0;
    max-height: 0;
  }
}

@mixin formElementTextIcon() {
  .form_element {
    margin: 0;

    &__label {
      display: grid;
      grid-template-columns: 25px 1fr;
      grid-auto-flow: dense;
      cursor: pointer;
      gap: 20px;

      .form_element__label__text {
        grid-column-start: 2;
      }
    }

    &.prefixIcon {
      flex-direction: row-reverse;
    }

    &__prefix_icon {
      margin: 0 -5px 0 0;
    }

    &.checkbox:focus-within {
      .checkbox-checked-primary {
        box-shadow: none;
      }
    }
  }
}

@mixin setupScrollBar($trackMargin: 22px 0) {
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: $bg-standard;
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: $bg-standard;
    margin: $trackMargin;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: $shade-color;
    border-radius: 10px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: $shade-darker;
  }
}

@mixin display($type: flex, $justify: flex-start, $align: center){
  display: $type;
  justify-content: $justify;
  align-items: $align;
}
