.gray-background {
  background-color: var(--shade-light) !important;
  flex-grow: 1;
  flex-direction: column;
  border-top: 1px solid var(--shade-color);
  margin-top: 16px;

  .research-purpose {
    margin-left: 27px;
    margin-top: 7px;
    display: flex;
    flex-direction: column;

    .research-purpose-header {
      display: flex;
      align-items: center;
      cursor: pointer;
      margin-right: 15px;

      .text {
        color: var(--foreground-color);
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-right: 8px;
      }
    }

    .research-purpose-list {
      display: flex;
      flex-direction: column;
      gap: 7px;
      margin-top: 14px;
      margin-bottom: 0;
      max-height: 100px;
      overflow-y: auto;
      padding-right: 10px;

      &::-webkit-scrollbar {
        width: 6px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: var(--primary-color);
        border-radius: 3px;
      }

      .list-item {
        display: flex;
        cursor: pointer;

        &:hover {
          color: var(--primary-color);
          cursor: pointer;
        }

        .list-item-content {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;

          .icon-container {
            display: flex;
            align-items: center;

            .icon {
              font-size: 18px;
              flex-shrink: 0;
            }
          }
        }
      }
    }

    .add-new {
      display: flex;
      align-content: center;
      cursor: pointer;
      gap: 6px;
      margin-top: 15px;
      margin-bottom: 14px;
      margin-right: 40%;

      &:hover {
        color: var(--primary-color) !important;

        .research-icon {
          color: var(--primary-color) !important;
        }

        .text {
          color: var(--primary-color) !important;
        }
      }
    }

    .first-item {
      .highlighted-text {
        font-weight: 600;
        color: orange;
        cursor: default;
      }
    }

    .primary-text {
      color: var(--primary-color) !important;
    }

    .collapsed-item {
      .highlighted-text {
        font-weight: 600 !important;
        margin-bottom: 12px;
      }
    }
  }
}
