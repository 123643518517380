[id^="my-sources-handler-popup"] {
  top: unset;
  bottom: 45px;
}

.my-sources {
  min-width: 425px;
  right: 0;
  margin-top: 15px;
  z-index: 2;

@media screen and (max-width: map-get($breakpoints, "xs")) {
  width: 100%;
  min-width: calc(100vw - 50px);
}

  &__header {
    font-weight: $fw-700;
    margin: 0;
  }

  &__add-btn {
    position: absolute;
    top: 10px;
    right: 18px;

    ion-icon {
      font-size: $fz-32;
    }
  }

  &__item {
    &__action-bar {
      display: flex;
    }
  }

  &__icon {
    height: 18px;
    width: 18px;

    ion-icon {
      color: $shade-color !important;
      font-size: 15px;
    }

    &:hover {
      background: none !important;

      ion-icon {
        color: $shade-darker !important;
      }
    }
  }

  &__text-field {
    margin: 0;
  }

  &__info {
    font-family: $font-family;
    font-style: $fs-standard;
    font-weight: $fw-standard;
    font-size: $fz-14;
    line-height: $lh-19;
    color: $shade-dark;
    margin-left: 20px;
  }

  &__error {
    margin: 0;
    color: $danger-dark;
  }


  .watchlist_dialog {
    all: unset;

    .vs--single.vs--open .vs__selected {
      position: static;
    }

    .vs__dropdown-option--highlight {
      color: white;
    }

    &__button_line {
      padding-top: 0;
    }

  }

}