.video-popupPreview{
  @media screen and (max-width: map_get($breakpoints, xlarge)) {
    width: 640px;
    height: 360px;
    max-width: 100%;
  }
  @media screen and (min-width: map_get($breakpoints, xlarge)) {
    width: 854px;
    height: 480px ;
  }
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-shadow: $active-shadow;
  border: $standard-border;

  #video-popupPreview-closer{
    position: absolute;
    right: 15px;
    top: 15px;
  }

  .fake-img{
    border: 0;
  }

  .preview__title, .preview__author{
    text-align: left;
    margin-left: 15px;
  }


  .preview__big-video{
    margin: 0;

    @media screen and (max-width: map_get($breakpoints, xlarge)) {
      width: 640px;
      height: 360px;
    }
    @media screen and (min-width: map_get($breakpoints, xlarge)) {
      width: 854px;
      height: 480px;
    }
  }

  &__text{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 60px;
    background:  linear-gradient(
                    rgba(255,255,255,1),
                    rgba(255,255,255,1),
                    rgba(255,255,255,0));
  }
}