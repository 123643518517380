.category_selector {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 0px;

  a {
    text-decoration: none;
    color: $fg-standard;
  }

  &__category {
    margin: 10px 10px 0 0;

    font-weight: 700;
    background: $shade-light;
    border-radius: 16px;
    height: 32px;


    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 5px 10px;

    white-space: nowrap;
  }
}
