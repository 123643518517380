.teaser {
  display: flex;
  flex-direction: column;
  margin-bottom: 60px;

  &__title {
    font-weight: 800;
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 20px;
  }

  &__under_title {
    font-weight: normal;
    margin-bottom: 20px;
    position: relative;
  }

  &__button {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: 50px auto auto;
    height: fit-content;
    width: fit-content;
  }

  &__text {

    position: relative;
    //color: transparent;
    //text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;

    &__blur {
      //-webkit-mask-image: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 110%);
      //-webkit-background-clip: text;
      filter: blur(4px);
    }
  }

  // This is copied from document.scss, todo: Consolidate with document.scss
  pre {
    margin-top: 5px;
    margin-bottom: 5px;
    white-space: pre-wrap;
    font-family: $font-family;

    &:first-of-type {
      margin-top: 24px;
      display: block;
      vertical-align: bottom;
      color: $bg-standard;
      white-space: normal;
    }
  }

  pre.preTable {
    white-space: pre !important;
    font-family: monospace !important;
  }

  img {
    max-width: 100%;
    height: auto;
  }
}
