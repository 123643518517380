 @media print {
   //* {
   //  outline: 1px solid #f00 !important; // for debugging
   //}
    .content,
    .result-div,
    .article__text__bar__actions,
    {
      display: block !important;
    }

    .request-text {
      display: flex;
      flex-direction: column;
    }

    .list-active .list-container {
      height: 100%;
      display: unset;
    }

    .article .buy_button {
      visibility: visible !important;
      opacity: 1 !important;
      width: fit-content;
    }

    .article {
      max-width: unset !important;
      margin: 5px 0 !important;
      pointer-events: none;
      break-inside: avoid;
      height: fit-content;
      display: table;
      border: 1px solid transparent;

      & > * {
        display: table-cell;
        vertical-align: top;
      }

      &__img {
        width: 140px;
      }

      &:hover {
        box-shadow: none;
      }
    }

   .article.list-view {
     .article__img {
       width: 20px;
     }

     .article__source {
       margin-right: 10px;
     }
   }

    .searchResult__summary__articlesFound {
      position: relative !important;
    }

    .os-padding {
      all: unset;
    }

    .searchResult,
    .os-padding {
      position: unset !important;
    }
    .searchResult__summary__articlesFound__count {
      margin: 0 !important;
    }

    .searchResult {
      padding-left: 0 !important;
    }
    .searchResult.left_aligned,
    .searchResult.shifted_right {
      margin-left: 0;
      overflow-y: visible !important;
    }
    .searchResult .result-div.own_scroll {
      max-height: none !important;
    }
    .searchResult .list-view-wrapper {
      padding: unset !important;
    }
    .main {
      min-height: unset !important;
    }
    .maincontainer_search,
    .searchResult .list-view-wrapper {
      width: unset !important;
    }

    .searchResult__articles {
      margin: 0 !important;
    }

    .modal_container,
    .sidePanel,
    .extra_navigation_line__right,
    .searchResult__summary__menu,
    .preview__div,
    .loader,
    .right-panel,
    .myNav,
    .os-content-glue {
      display: none !important;
    }
   .searchResult, .searchResult__articles, .replaceable_result, .maincontainer_search{
     display: unset !important;
   }
 }

