@import "../mixins/index";

.browse_page {
  position: relative;
  z-index: 1;
  background-color: $bg-standard;
  flex-grow: 1;
  width: 100%;
  overflow-x: hidden;
  margin-left: auto;
  margin-right: auto;
  padding: 0 10px 0 30px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  &.own_scroll {
    max-height: 100vh;
    //overscroll-behavior-y: contain;
  }

  &__title {

    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0;
    margin-bottom: 25px;

    &__text {
      font-weight: $fw-800;
      font-size: 48px;
      line-height: 65px;
      display: flex;
      align-items: center;
    }

  }

  &__subtitle {
    font-weight: $fw-700;
    font-size: $fz-26;
    line-height: $lh-30;
    display: flex;
    align-items: center;
    margin: -40px 0 20px;

    &:first-of-type {
      margin-top: 20px;
    }
  }

  #browse-toolbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .sourceFilterArea {

      .filter__item_search__box {
        height: 46px;
      }
    }

    @media screen and (max-width: map_get($breakpoints, small)) {

      display: block;

      .sourceFilterArea {
        width: auto;
        height: 46px;
      }


      .action_bar_container:not(.sticky_action_bar) {
        display: block;
        margin-top: 5px;

        .action_buttons_line {
          justify-content: end;
        }
      }
      .action_buttons_line {
        _margin: 10px;
      }

    }

  }

  .line {
    order: revert;
  }

  @keyframes append-animate {
    from {
      transform: scaleY(0);
      opacity: 0;
    }
    to {
      transform: scaleY(1);
      opacity: 1;
    }
  }

  /* animate new area */
  .partialSourceListArea-new {
    transform-origin: 50% 0;
    animation: append-animate .3s linear;
  }


  // override sizes for tiles and panes in browsing pages
  --fudge1: 40px;
}

.preview__div.browse {
  // override sizes for tiles and panes in browsing pages
  --fudge1: 40px;
}

.archive__older_link {
  color: $primary-color;
  display: flex;
  align-content: center;
  justify-content: center;

  padding-bottom: 30px;
  font-family: $font-family;
  font-size: $fz-standard;
  line-height: $lh-standard;

  &:hover {
    text-decoration: underline;
  }
}

.partialSourceListArea {
  @media screen and (max-width: map_get($breakpoints, small)) {
    .media_tile {
      width: 100%;
    }
  }
}

.source {
  position: relative;

  &__filters {

  }

  &__404 {
    font-size: $fz-14;
    color: $fg-standard;
    margin: 0;

    ion-icon {
      color: $primary-color;
    }

    b {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      column-gap: 10px;
      font-size: $fz-standard;
      margin-bottom: 10px;
    }
  }

  &__button {
    border-color: $shade-light;

    &.active {
      box-shadow: $active-shadow;
    }
  }

  &__desktop {
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 28px;

    @media screen and (max-width: map-get($breakpoints, small)) {
      display: none;
    }

    &__action-bar {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  &__mobile {
    display: none;

    &-action-bar {
      display: flex;
      position: sticky;
      position: -webkit-sticky;
      bottom: 0;
      justify-content: flex-end;
      align-items: center;
      padding: 20px 30px;
      column-gap: 15px;
      background-color: $bg-standard;
      z-index: 5;
      box-shadow: inset 0 1px 0 $border-light-color;
      margin-top: -1px; //without this, border-top, when sticky not set, become 2px
    }

    @media screen and (max-width: map-get($breakpoints, small)) {
      display: block;
    }

    &-popup {
      position: fixed;
      top: 0;
      height: 100vh;
      width: 100vw;
      z-index: 99999999;
      background: $bg-blocker;

      &__container {
        position: absolute;
        bottom: 0;
        width: 100%;
        background: $bg-standard;
        display: flex;
        flex-direction: column;
        overflow: hidden;

        @include setupScrollBar(0);

        overflow-y: overlay;
        overflow-y: auto;
        height: -webkit-fill-available;
      }
    }

    &-element {
      border-bottom: $light-border;

      @include formElementTextIcon;

      .source__close-btn {
        display: none;
      }
    }

    &-list {
      position: relative;
      padding: 20px 30px 30px 20px;
      margin-top: -20px;
      display: grid;
      row-gap: 15px;
      max-height: 500px;

      overflow: hidden;

      @include setupScrollBar;

      overflow-y: overlay;

      z-index: 5;

      @include animationGrow;

      @include animationFlipList;

      @include formElementTextIcon;
    }

    &-toggle-btn {
      display: grid;
      grid-template-columns: 1fr 24px;
      justify-content: space-between;
      align-items: center;
      padding: 20px 30px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-weight: $fw-700;
      font-size: $fz-standard;
      line-height: $lh-standard;
      cursor: pointer;
    }
  }

  &__checkbox-form {
    display: flex;
    flex-direction: column;
    row-gap: 10px;

    overflow: hidden;

    @include setupScrollBar;

    overflow-y: overlay;

    .empty {

      .form_element__label {
        span {
          color: $shade-color !important;
        }
      }
    }

    @include formElementTextIcon;
  }

  &__popup {
    position: absolute;
    top: 45px;
    max-width: 295px;
    width: 100%;
    max-height: 500px;
    overflow: hidden;

    @include setupScrollBar;

    overflow-y: overlay;

    z-index: 5;

    @include animationGrow;

    @include animationFlipList;

    @include formElementTextIcon;
  }

  &__action-bar {
    position: sticky;
    display: flex;
    justify-content: flex-end;
    column-gap: 10px;
    bottom: -20px;
    padding: 10px 0 5px 0;
    background: $bg-standard;
  }

  &__spinner {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $bg-standard;
    opacity: 70%;
  }

  .update-page-loader {
    pointer-events: none;
    color: $shade-color;
  }

  &__show-more {
    display: flex;
    color: $secondary-color;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
      color: $secondary-dark;
    }

    &:active {
      color: $secondary-darker;
    }
  }

  &__close-btn {
    color: $primary-color;
    cursor: pointer;
    margin-left: auto;

    &:hover {
      text-decoration: underline;
      color: $primary-dark;
    }

    &:active {
      color: $primary-darker;
    }
  }
}
