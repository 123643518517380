.right-drawer {
  width: 238px;
  min-height: 100%;
  align-items: start;
  flex-shrink: 0;
  border: none !important;
  border-radius: 22px;
  background: var(--background-color);
  box-shadow: 0 0 20px 0 var(--border-light);
  z-index: 1000;
  overflow-x: hidden;

  ion-icon {
    margin-left: auto;
    flex-shrink: 0;
    width: 24px;
    height: 24px;
  }

  .research-icon {
    font-size: 18px;
  }

  .menu-item-wrapper {

    &:hover > .navigation-options,
    .navigation-sub-options.hovered {
      color: var(--primary-color);
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .navigation-options {
    margin-left: 30px;
    margin-right: 15px;
    padding: 10px 0;
    display: flex;
    align-items: center;
    color: var(--foreground-color);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    &.selected {
      color: var(--primary-color);
      text-decoration: underline;
    }
  }

  .sub-items {
    margin-left: 20px;

    .navigation-sub-options {
      margin: 0 17px 8px 17px;
      height: auto;
      display: flex;
      align-items: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      color: var(--foreground-color);

      &.last-sub-item {
        margin-bottom: 8px;
      }

      &.selected {
        color: var(-io-primary-color);
        text-decoration: underline;
      }
    }
  }

  @media (hover: hover) {
    .menu-item-wrapper:hover > .navigation-options,
    .navigation-sub-options.hovered {
      color: var(--primary-color);
      cursor: pointer;
      text-decoration: underline;
    }
  }

  @media (pointer: coarse) {
    .menu-item-wrapper:active > .navigation-options,
    .navigation-sub-options:active {
      color: var(--primary-color);
      text-decoration: underline;
    }
  }

  .nav-link {
    text-decoration: none;
    color: inherit;
  }
}
