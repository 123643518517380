.flexible_container {
  max-width: 640px;
  width: calc(100vw - 10px) !important;

  #overlay_content {
    width: 100%;
  }
}

.press_notification_form {

  &__header {
    font-size: $fz-standard;
    line-height: $lh-standard;
    font-weight: $fw-700;
    margin-bottom: 20px;
  }

  &__description {
    font-size: $fz-14;
    font-weight: $fw-standard;
    margin-bottom: 20px;
  }

  &__form {
    width: 100%;
    display: flex;
    flex-direction: column;

    .monitoring_form__form__settings__schedule {
      margin-top: 0;
      margin-bottom: 30px;
    }

    .email_group {
      margin-bottom: 0;
    }

    .email_group__list {
      max-width: 250px;

      .form_element {
        margin-bottom: 5px;
      }
    }


    &__emails {
      display: grid;
      grid-template-columns: 1fr 40px 40px;
      column-gap: 5px;
    }
  }

  &__error {
    margin-bottom: 10px;
  }

  &__submit_container {
    border-top: $standard-border;
    display: flex;
    padding: 30px 30px 0;
    margin: 0px -30px;

    .button__secondary {
      margin-left: auto;
      margin-right: 10px;
    }
  }
}