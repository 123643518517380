.content {

  @media screen and (max-width: map_get($breakpoints, small)) {
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
  }
}

.grid-view {
  display: grid;
  grid-template-columns: repeat(auto-fill, 255px);
  grid-auto-rows: minmax(386px, auto);
  width: 100%;
}

//TODO: not using
//.grid-view-item {
//  display: flex;
//  flex-direction: column;
//  justify-content: center;
//  align-items: center;
//  padding: 20px;
//
//
//  background: $shade-light;
//  border-radius: $border-radius-10;
//
//  flex: none;
//  order: 1;
//  flex-grow: 1;
//
//  @media screen and (max-width: map_get($breakpoints, medium)) {
//    width: 167px;
//  }
//
//  @media screen and (max-width: map_get($breakpoints, small)) {
//    width: 157px;
//  }
//
//  &__text{
//
//    padding: 0;
//    flex: none;
//    order: 1;
//    align-self: stretch;
//    flex-grow: 0;
//
//  }
//
//}

@media screen and (max-width: map_get($breakpoints, large)) {
  .searchResult {
    //grid-template-areas:

  }

  .grid-view {
    grid-template-columns: repeat(auto-fill, 250px);
    grid-auto-rows: minmax(386px, auto);
  }

}

@media screen and (max-width: map_get($breakpoints, medium)) {


  .grid-view {
    grid-template-columns: repeat(auto-fill, 167px);
    grid-auto-rows: minmax(342px, auto);
  }

  .grid-view-item {
    padding: 10px;
  }

}

@media screen and (max-width: map_get($breakpoints, small)) {


  .grid-view {
    //grid-template-columns: repeat(1, 1fr);
    grid-template-columns: repeat(auto-fill, 157px);
    grid-auto-rows: minmax(328px, auto);
  }

  .grid-view-item {
    padding: 10px;
  }

}


