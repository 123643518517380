.registration {
  &__container {
    display: grid;
    grid-template-columns: 1.5fr 2fr;
    gap: 30px;
    padding: 0 100px;
    margin: 50px auto;
    max-width: 1400px;
    width: 100%;
  }

  &__left-panel {
    &__title {
      margin-top: 55px;
      font-weight: 800;
      font-size: 26px;
    }

    &__list {
      list-style: none;
      padding: 0;
    }

    &__list-item {
      display: flex;
      align-items: center;
      gap: 10px;
      border-bottom: 1px solid $shade-color;
      padding: 20px 0;
    }
  }

  &__right-panel {
    background-color: $shade-light;
    box-shadow: $dropdown-shadow;
    padding: 55px 60px 40px 60px;
    height: fit-content;

    &__title {
      margin-top: 0;
      font-weight: 800;
      font-size: 26px;
    }

    .form_element.text {
      background-color: $bg-standard;
    }

    @media screen and (max-width: 750px) {
      padding: 20px;
    }

    @media screen and (max-width: map-get($breakpoints, small)) {
      min-height: unset;
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
    row-gap: 10px;

    &__username {
      display: flex;
      flex-direction: row;
      gap: 10px;
    }

    &__registration-data {
      display: flex;
      flex-direction: column;

      .error {
        margin-top: -15px;
        margin-bottom: 10px;
        padding-left: 15px;
      }
    }

    &__mandatory {
      font-weight: $fw-standard;
      font-size: $fz-12;
      color: $shade-dark;
      margin: 0 0 0 auto;
      padding: 0;
      display: flex;
      justify-content: end;
    }

    .radio__group {
      column-gap: 10px;

      .radio-container {
        column-gap: 8px;
        cursor: pointer;
      }
    }
  }

  &__submitBtn {
    display: flex;
    justify-content: end;

    button {
      padding: 5px 14px !important;
    }

    @media screen and (max-width: map_get($breakpoints, small)) {
      .button__secondary span.btn-text, .button__primary span.btn-text {
        display: block;
      }
    }
  }

  &__submit-error {
    margin: 56px 0 10px 0;
  }

  @media screen and (max-width: map_get($breakpoints, small)) {
    &__left-panel {
      display: none;
    }

    &__container {
      display: block;
      padding: 15px;
    }
  }
}