@mixin ripple($hover-color, $active-color) {
  &:not([disabled]) {
    &:hover {
      background: $hover-color radial-gradient(circle, transparent 1%, $hover-color 1%) center/15000%; //do not set !important, effect will not work
    }

    &:active {
      background-color: $active-color;
      background-size: 100%;
      transition: background 0s !important;
    }
  }
}

@mixin btnTemplate() {
  position: relative;
  border-radius: $border-radius-22;
  padding: 5px 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background 0.8s, all 0.3s ease;
  background-position: center;
  gap: 3px;
  z-index: 1;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  width: fit-content;

  span {
    display: inline-flex;
    font-family: 'Open-Sans', sans-serif;
    font-weight: $fw-standard;
    line-height: $lh-standard;
    font-size: $fz-standard;
    z-index: 4;
    margin: 0;

    ion-icon {
      font-weight: 600;
    }

    @media screen and (max-width: map_get($breakpoints, small)) {
      &.btn-text {
        display: none;
      }
    }
  }

  &:focus-visible {
    outline: $outline;
  }

  &:disabled {
    cursor: default;
  }

}

.button {

  &__primary {
    @include btnTemplate;
    background: $primary-color;
    background-size: 300%;
    color: $text-over-primary-color;
    border: 1px solid $primary-color;

    &:link {
    }

    ion-icon {
      color: $text-over-primary-color !important;
    }

    @include ripple($primary-dark, rgba(255, 255, 255, 0.1));

    &:disabled {
      background: $shade-light;
      border-color: $shade-light;
      color: $shade-color;

      ion-icon {
        color: $shade-color !important;
      }
    }
  }

  &__secondary--filled {
    // this is not in figma but used. It looks like a primary button but has secondary colours
    @include btnTemplate;
    background: $secondary-color;
    background-size: 300%;
    color: $text-over-primary-color;
    border: 1px solid $secondary-color;

    &:link {
    }

    ion-icon {
      color: $text-over-primary-color !important;
    }

    @include ripple($secondary-dark, rgba(255, 255, 255, 0.1));

    &:disabled {
      background: $shade-light;
      border-color: $shade-light;
      color: $shade-color;

      ion-icon {
        color: $shade-color !important;
      }
    }
  }

  &__secondary, &__primary:visited {
    @include btnTemplate;
    color: $fg-standard;
    background-color: $bg-standard;
    border: 1px solid $fg-standard;

    ion-icon {
      color: $fg-standard !important;
    }

    &:focus {
      background-color: $btn-secondary-focus;
    }

    &[aria-label="archive"] {
      color: $fg-standard !important;

      &:hover {
        text-decoration: none;
      }
    }

    @include ripple($shade-light, rgba(128, 128, 128, 0.4));

    &:disabled {
      color: $shade-color;

      ion-icon {
        color: $shade-color !important;
      }

      border-color: $shade-light;
    }

    div.lds-ring {
      div {
        border-color: $fg-standard transparent transparent transparent
      }
    }

  }

  &__icon {
    @include btnTemplate;
    color: $fg-standard;
    background-color: inherit;
    border: 1px solid rgba(0, 0, 0, 0);
    height: 40px;
    width: 40px;
    padding: 0;

    &.icon-null {
      padding: 3px !important;
    }

    ion-icon {
      color: $fg-standard !important;
    }

    &:focus {
      background-color: $btn-secondary-focus;
    }

    @include ripple($shade-light, rgba(128, 128, 128, 0.4));

    &:disabled {
      color: $shade-color;

      ion-icon {
        color: $shade-color !important;
      }
    }

    div.lds-ring {
      div {
        border-color: $fg-standard transparent transparent transparent
      }
    }

  }
}

.panel-btn {
  width: 100%;
  justify-content: flex-end;
  border-radius: 0;
  height: 76px;
  padding-left: 30px;
  gap: 10px;
  margin-bottom: 2px;

  &:focus {
    background-color: $bg-standard;
  }

  &:hover {
    background-color: $shade-light;
  }

  &.icon-null {
    padding-left: 30px !important;
  }
}

//browsePage -> serverIcon in media tile
button[js-handler="mySourcesHandler"] {
  &.button-loader {
    ion-icon {
      display: none;
    }
  }

  &.button-server-outline {
    ion-icon[name="server"], .lds-ring {
      display: none;

      div {
        animation: unset;
      }
    }
  }

  &.button-server {
    ion-icon[name="server-outline"], .lds-ring {
      display: none;

      div {
        animation: unset;
      }
    }
  }
}
