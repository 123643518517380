@media print {
  @page {
    margin: 50px 20px;
  }
  :root {
    --tiles: 4;
  }
  img {
    display: block;
    margin-top: 10px;
    page-break-inside:avoid !important;
  }

  html.os-html, html.os-html > .os-host,
  .os-host-overflow,
  .os-host,
  .os-host-textarea,
  .os-viewport,
  .os-content,
  #os-dummy-scrollbar-size:before,
  #os-dummy-scrollbar-size:after,
  .os-content:before,
  .os-content:after,
  .modal_container,
  .moduleDocumentGraphic
  {
    all: unset !important;
  }

  .header,
  .sticky,
  .document_additional_info,
  .document__navigation,
  .extra_navigation_line,
  .document__container .document_and_metadata_container .document-metadata,
  .footer,
  .sidenav,
  ._hj_feedback_container,
  .os-resize-observer,
  .os-resize-observer-host,
  .os-scrollbar,
  .os-scrollbar-corner,
  #transparentPopUp,
  .media_type_selector,
  .modal_container,
  .action_bar_container,
  .nav,
  .mein-genios-template__actions,
  .header-action-bar
  {
    display: none !important;
  }

  .os-padding {
    bottom: unset;
  }

  .document {
    border: none !important;

    .with-fixed-size {
      margin-top: 0 !important;
      margin-left: 0 !important;
      width: 100% !important;
    }
  }

  span {
    display: flex;
    padding: 0 !important;
    gap: 5px;
  }
  .media_tile{
    page-break-inside: avoid;
  }
  nav ul.pagination li.page-item.page-number.selected{
    -webkit-print-color-adjust: exact !important;
    color-adjust: exact !important;
    print-color-adjust: exact !important;
  }
  .article__text__panelBody, .grid-active .search-result-item-container, .partialSourceListArea .searchResult__media-items-flex{
    display: unset !important;
  }
}