@import "./../mixins/index";

.video {

  .boldLarge {
    font-size: $fz-26 !important;
  }

  &__frame {
    width: 100%;
    border: 0;
    height: 500px;
    margin-bottom: 25px;
    overflow: hidden;
  }

  .studyFlix {
    height: 100%;
  }

  &__link {
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 5px;
    font-size: $fz-14;

    &:visited {
      color: $secondary-color;

      &:hover {
        color: $secondary-color;
        text-decoration: underline;
      }
    }

    &__play {
      display: grid;
      grid-template-columns: 24px 10px 1fr;
      align-items: center;
      column-gap: 10px;

      &__title {
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .lds-ring {
        display: flex;
        align-items: center;
        justify-content: center;

        div {
          width: 19.5px !important;
          height: 19.5px !important;
          border-color: $primary-color transparent transparent transparent;
        }
      }
    }

    &.selected__link {
      color: $primary-color;

      &:hover {
        color: $primary-color;
        text-decoration: underline;
      }
    }

    &:first-of-type {
      margin-top: 20px;
    }

    &:last-of-type {
      margin-bottom: 20px;
    }
  }

  &__attachment {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: $fz-14;
    column-gap: 10px;

    &__title {
      display: grid;
      grid-template-columns: 24px 1fr;
      align-items: center;
      column-gap: 10px;
    }

    &__text {
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &:first-of-type {
      margin-top: 20px;
    }

    &:last-of-type {
      margin-bottom: 20px;
    }
  }

  &__footer {


    &__logo {
      //display: flex;
      //align-items: center;
      //column-gap: 10px;
      margin: 10px 0;
    }
  }

  &__full-document {
    display: none;
  }

  &__document-link {
    color: $fg-standard !important;

    &:hover {
      color: $primary-color !important;
      text-decoration: underline;
    }

    &:visited {
      color: $secondary-color !important;

      &:hover {
        color: $secondary-color !important;
        text-decoration: underline;
      }
    }
  }
  
  &__player {
    &__title {
      white-space: break-spaces;
      hyphens: auto;
      font-weight: 700;
    }
  }

}

.video-preview {
  @include documentMedata;

  .document-metadata {
    all: unset;
  }

  .media_preview__full_doc {
    display: none;
  }

  .media_preview__main {
    display: none;
  }

  .video__link, .video__attachment {
    color: $fg-standard !important;

    &:hover {
      color: $primary-color !important;
    }

    &.selected__link {
      color: $primary-color !important;
    }

    &.paid {
      color: $secondary-color !important;
    }
  }

  .video {
    .video__footer {
      display: none;
    }
  }

  .video__full-document {
    display: block;
  }

}