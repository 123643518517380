.vertical-resizable-divider {
  height: 100%;
  position: relative;
  background-color: $bg-standard;
  z-index: $divider_z_index;
  border-left: 1px solid $shade-light;

  .divider-button {
    position: sticky;
    margin-left: -12px;
    z-index: calc(#{$divider_z_index} + 3) !important;
    top: 50%;
    transform: translateY(-50%);
    background: $shade-light;
    border: 1px solid $shade-light;
    width: 22px;
    height: 22px;

    ion-icon {
      height: 20px;
      width: 20px;
      color: $shade-dark !important;
    }
  }

  .divider-button:hover {
    background: var(--primary-color) !important;
    border: 1px solid var(--primary-color);

    ion-icon {
      color: var(--text-over-primary-color) !important;
    }
  }
}

.slider {
  z-index: calc(#{$divider_z_index} - 1);
  position: relative;
  height: 100%;
  top: -30px;
  min-width: 3px;
  width: 3px;
  background-color: transparent;
  cursor: col-resize;


  @media screen and (max-width: map_get($breakpoints, medium)) {
    pointer-events: none;
  }

  &::before {
    content: '';
    position: absolute;
    transform: translateX(-50%);
    width: 2px;
    left: -1px;
    height: 100%;
    background-color: var(--border-light);
  }
}

.slider.not-draggable {
  pointer-events: none;
  background-color: transparent;
}

.slider:not(.not-draggable):hover::before {
  background-color: $primary-color;
}

.smooth {
  transition: margin-left 0.4s ease-in-out;

  @media screen and (max-width: map_get($breakpoints, medium)) {
    transition: none;
  }
}